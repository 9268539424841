import React, { useContext } from 'react';
import styled from 'styled-components';
import ProgressLineChart from '../../../shared/components/Charts/ProgressLine';
import {
  StyledModal,
  ModalContent,
  ModalHeader,
  ModalText,
  ButtonContainer,
} from '../../../shared/styles/ModalStyles';
import { UserContext } from '../../../shared/contexts/UserContext';
import SpinnerLoader from '../../../shared/components/Loader';
import { useNavigate } from 'react-router-dom';
import { Typography, Link } from '@mui/material';

const StyledButton = styled.button<{ primary?: boolean }>`
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: 8px;
  font-size: 16px;
  background-color: ${(props) =>
    props.primary ? 'var(--color-primary-light-blue)' : '#f1f1f1'};
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: ${(props) => (props.primary ? '#adc7f0' : '#e1e1e1')};
  }
`;

type ReviewModalProps = {
  open: boolean;
  consumedDeepDives: number;
  maxConsumableDeepDivesInSubPeriod: number;
  cancelText: string;
  onCancel: () => void;
  onProceed?: () => void;
};

const ReviewUnlockModal: React.FC<ReviewModalProps> = ({
  open,
  consumedDeepDives,
  maxConsumableDeepDivesInSubPeriod,
  onCancel,
  cancelText,
  onProceed,
}) => {
  const isPlanMaxed =
    maxConsumableDeepDivesInSubPeriod - consumedDeepDives <= 0;

  const { userLoaded } = useContext(UserContext);

  const navigate = useNavigate();

  if (!userLoaded) {
    return <SpinnerLoader loaderText="Loading Subscription Plan" />;
  }

  return (
    <StyledModal
      open={open}
      onClose={onCancel}
      aria-labelledby="review-modal-title"
      aria-describedby="review-modal-description"
    >
      <ModalContent>
        <ModalHeader>
          {!isPlanMaxed ? 'Wait!' : 'No Deep Dive Credits Left'}
        </ModalHeader>

        <ProgressLineChart
          consumed={consumedDeepDives}
          total={maxConsumableDeepDivesInSubPeriod}
          subText={consumedDeepDives + '/' + maxConsumableDeepDivesInSubPeriod}
          title="Deep Dive Reviews Consumed"
        />

        {!isPlanMaxed ? (
          <ModalText>
            Unlocking this review will leave you with{' '}
            <strong data-testid="post-unlock-count">
              {maxConsumableDeepDivesInSubPeriod - consumedDeepDives - 1}
            </strong>{' '}
            reviews remaining. Do you wish to proceed?
          </ModalText>
        ) : (
          <ModalText>
            You have reached your plan limit. Please reach out to your Kandir
            representative to purchase more credits.
          </ModalText>
        )}

        <ButtonContainer>
          <StyledButton onClick={onCancel}>{cancelText}</StyledButton>
          {isPlanMaxed && (
            <StyledButton primary onClick={() => navigate('/pricing')}>
              Purchase Credits
            </StyledButton>
          )}
          {!isPlanMaxed && (
            <StyledButton primary onClick={onProceed}>
              Proceed
            </StyledButton>
          )}
        </ButtonContainer>
        <Typography variant="body2" sx={{ mt: 2 }}>
          Want to unlock more Deep Dives? For each Deep Dive you submit we'll
          add one additional review to consume to your account. Or you can go to
          a{' '}
          <Link href="/pricing" variant="body2" target="_blank">
            paid plan
          </Link>
          .
        </Typography>
      </ModalContent>
    </StyledModal>
  );
};

export default ReviewUnlockModal;
