import axios from 'axios';
import { BasicReview } from '../../../shared/domain/review';
import { Company } from '../../authentication/types/types.auth';

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

export type RatingsInput = {
  companyId: number;
  reviewId: number;
  ratings: RatingValues;
};

export type RatingValues = {
  transparency: number;
  clarity: number;
  understanding: number;
  responsiveness: number;
};

export const createCompanyFromReview = async (
  newCompany: any,
  idToken: string
): Promise<Company> => {
  const response = await axios.post(`${API_BASE_URL}/company`, newCompany, {
    headers: {
      Authorization: idToken,
    },
  });

  return response.data;
};

export const createBasicReview = async (
  reviewInput: BasicReview,
  idToken: string
): Promise<number> => {
  const response = await axios.post(`${API_BASE_URL}/review`, reviewInput, {
    headers: {
      Authorization: idToken,
    },
  });
  return response.data;
};

export const createBasicReviewRatings = async (
  ratingsInput: RatingsInput,
  idToken: string
) => {
  return await axios.post(`${API_BASE_URL}/reviews/ratings`, ratingsInput, {
    headers: {
      Authorization: idToken,
    },
  });
};

export const getAllCompanyBasicReviews = async (
  companyId: number,
  idToken: string
): Promise<BasicReview[] | null> => {
  if (!companyId || !idToken) return null;
  const res = await axios.get(
    `${API_BASE_URL}/reviews-companies/${companyId}`,
    {
      headers: {
        Authorization: idToken,
      },
    }
  );
  return res.data;
};
