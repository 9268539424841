import React, {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import axios from 'axios';
import {
  BaseReview,
  BasicReview,
  CurrentReview,
  ReviewStep,
} from '../../../shared/domain/review';
import { SnackBarConfig } from '../../../shared/components/Snackbar';
import { UserContext } from '../../../shared/contexts/UserContext';
import { getAllCompanyBasicReviews } from '../api/api';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';

interface ReviewContextType {
  snackbarConfig: SnackBarConfig | undefined;
  setSnackbarConfig: Dispatch<SetStateAction<SnackBarConfig | undefined>>;
  reviewStep: ReviewStep;
  setReviewStep: Dispatch<SetStateAction<number>>;
  setLastStepSaved: Dispatch<SetStateAction<number>>;
  lastStepSaved: number | null;
  reviewId: number | null;
  setReviewId: Dispatch<SetStateAction<number | null>>;
  currentReview: CurrentReview;
  handleServerError: () => void;
  handleFinishReview: (showSnackbar?: boolean) => void;
  allCompanyReviews: Array<BasicReview> | null;
  fetchAllReviews: (companyId: number) => void;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

export const ReviewContext = createContext<ReviewContextType | undefined>(
  undefined
);

export const useReviewContext = () => {
  const context = useContext(ReviewContext);
  if (!context) {
    throw new Error('useReview must be used within a ReviewProvider');
  }
  return context;
};

const ReviewProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentReview, setCurrentReview] = useState<BaseReview | null>(null);
  const [snackbarConfig, setSnackbarConfig] = useState<SnackBarConfig>();
  const [reviewStep, setReviewStep] = useState<number>(0);
  const [reviewId, setReviewId] = useState<number | null>(null);
  const [lastStepSaved, setLastStepSaved] = useState<number>(0);
  const [allCompanyReviews, setAllCompanyReviews] = useState<BasicReview[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { user, userLoaded } = useContext(UserContext);

  const navigate = useNavigate();
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const redirectCompanyName = pathSegments[2];
  const redirectReviewId = pathSegments[3];

  const fetchAllReviews = async (companyId: number) => {
    if (!user?.idToken) return;
    setLoading(true);
    try {
      const res: BasicReview[] | null = await getAllCompanyBasicReviews(
        companyId,
        user?.idToken
      );
      setAllCompanyReviews(res || []);
    } catch (error) {
      handleServerError();
    } finally {
      setLoading(false);
    }
  };

  const handleServerError = () => {
    setSnackbarConfig({
      type: 'error',
      message: 'An error has occurred, please try again later.',
      open: true,
    });

    setCurrentReview(null);
    setReviewStep(ReviewStep.BASIC_REVIEW);
  };

  const handleFinishReview = (showSnackbar?: boolean) => {
    if (showSnackbar) {
      setSnackbarConfig({
        type: 'success',
        message: 'Review successfully submitted, thank you!',
        open: true,
      });
    }

    setCurrentReview(null);
    setLastStepSaved(0);
    setReviewId(null);
    setReviewStep(ReviewStep.BASIC_REVIEW);
  };

  const getCurrentReview = async (key?: ReviewStep) => {
    if (lastStepSaved < reviewStep) return;

    setLoading(true);

    try {
      const params = key !== undefined ? { key } : {};
      const response = await axios.get(
        `${import.meta.env.VITE_API_BASE_URL}/review/${reviewId}`,
        {
          params,
          headers: {
            Authorization: user?.idToken,
          },
        }
      );
      setCurrentReview(response.data);
    } catch (error) {
      const status = error.response?.status;

      if (status === 401) {
        setReviewId(null);
        navigate({
          pathname: '/sign-in',
          search: `?${createSearchParams({
            reviewId: String(redirectReviewId),
            companyName: redirectCompanyName,
            reAuth: 'true',
          })}`,
        });
        return;
      }

      if (status === 402) {
        navigate({
          pathname: `/company-search/${redirectCompanyName}`,
          search: `?${createSearchParams({
            checkReviewFromEmail: String(redirectReviewId),
          })}`,
        });
        return;
      }

      setSnackbarConfig({
        type: 'error',
        message: 'An error has occurred, please try again later.',
        open: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!reviewId || !userLoaded) return;

    getCurrentReview(reviewStep);
  }, [reviewId, reviewStep, userLoaded]);

  const value = {
    currentReview,
    setCurrentReview,
    setSnackbarConfig,
    handleServerError,
    handleFinishReview,
    snackbarConfig,
    allCompanyReviews,
    fetchAllReviews,
    reviewId,
    setReviewId,
    reviewStep,
    setReviewStep,
    loading,
    setLoading,
    setLastStepSaved,
    lastStepSaved,
  };

  return (
    <ReviewContext.Provider value={value}>{children}</ReviewContext.Provider>
  );
};
export default ReviewProvider;
