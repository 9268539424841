import { useContext } from 'react';
import { UserContext } from '../../../shared/contexts/UserContext';
import styled from 'styled-components';
import { H1, screenMdMin } from '../../../shared/styles';
import Button from '../../../shared/components/Buttons/Button';

type CompanyProfileHeaderProps = {
  name: string;
  openReviewDialog: (isOpen: boolean) => void;
  followCompany: () => void;
  isCompanyFollowed: boolean;
};

const StyledHeader = styled(H1)`
  padding: var(--spacing-md);
  margin: auto;
  text-align: center;
  text-decoration: underline;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  height: 50px;
  @media ${screenMdMin} {
    height: 30px;
  }
`;

const CompanyProfileHeader = (props: CompanyProfileHeaderProps) => {
  const { name, openReviewDialog, followCompany, isCompanyFollowed } = props;
  const { user } = useContext(UserContext);

  return (
    <>
      <HeaderWrapper>
        {user?.hasCompletedRegistration && (
          <Button
            testId="follow-company-button"
            size="medium"
            onClick={followCompany}
          >
            {isCompanyFollowed ? 'Unfollow' : 'Follow'} Company
          </Button>
        )}
        <StyledHeader>{name}</StyledHeader>

        {user?.hasCompletedRegistration && (
          <Button
            testId="leave-review-button"
            onClick={() => openReviewDialog(true)}
            size="medium"
          >
            Leave a Review
          </Button>
        )}
      </HeaderWrapper>
    </>
  );
};

export default CompanyProfileHeader;
