import React, { useContext, useEffect, useRef } from 'react';
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import {
  ChevronLeft,
  Search,
  Home,
  Settings,
  Logout,
  Login,
  Leaderboard,
} from '@mui/icons-material';
import styled from 'styled-components';
import { UserContext } from '../contexts/UserContext';
import { useResponsive } from '../hooks/useResponsive';
import kandirLogo from '../../assets/images/kandir_logo.png';

type SideDrawerProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  logOut: () => void;
};

export const Main = MuiStyled('main', {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'isMobile',
})<{
  open?: boolean;
  isMobile?: boolean;
}>(({ theme, open, isMobile }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: isMobile ? '0' : '240px',
  }),
}));

const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  padding: var(--spacing-md);
  justify-content: space-between;
`;

const StyledImage = styled.img`
  height: 80px;
  cursor: pointer;
`;

const drawerWidth = 240;

const SideDrawer = (props: SideDrawerProps) => {
  const { isOpen, setIsOpen, logOut } = props;
  const { user, setReload } = useContext(UserContext);
  const { isMobile } = useResponsive();
  const navigate = useNavigate();

  const NAV_MAPPINGS = [
    {
      text: 'Company Search',
      icon: <Search />,
      onClick: () => navigate('/company-search'),
    },
    {
      text: 'Account Settings',
      icon: <Settings />,
      onClick: () => navigate('/account-settings'),
    },
    user && user.isAdmin
      ? {
          text: 'Admin View',
          icon: <Leaderboard />,
          onClick: () => navigate('/admin'),
        }
      : null,
    {
      text: user ? 'Logout' : 'Login',
      icon: user ? <Logout /> : <Login />,
      onClick: user ? logOut : () => navigate('/sign-in'),
    },
  ];

  const hasFired = useRef(false);

  useEffect(() => {
    if (!hasFired.current) {
      setReload((prev) => prev + 1);
      hasFired.current = true;
    }
  }, []);

  return (
    <>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant={isMobile ? 'temporary' : 'persistent'}
        anchor="left"
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <DrawerHeader>
          <IconButton onClick={() => navigate('/welcome')}>
            <StyledImage src={kandirLogo} />
          </IconButton>
          <IconButton onClick={() => setIsOpen(false)}>
            <ChevronLeft />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate('/welcome')}>
            <ListItemIcon>
              <Home />
            </ListItemIcon>
            <ListItemText primary="Home"></ListItemText>
          </ListItemButton>
        </ListItem>

        <Divider />
        <List>
          {NAV_MAPPINGS.map((navLink, index) =>
            navLink ? (
              <ListItem key={index} disablePadding>
                {!user && navLink.text === 'Account Settings' ? (
                  <span></span>
                ) : (
                  <ListItemButton onClick={navLink.onClick}>
                    <ListItemIcon>{navLink.icon}</ListItemIcon>
                    <ListItemText primary={navLink.text} />
                  </ListItemButton>
                )}
              </ListItem>
            ) : null
          )}
        </List>
      </Drawer>
    </>
  );
};

export default SideDrawer;
