import { BasicReview } from '../../../shared/domain/review';
import { UserType } from '../../../shared/domain/user';

export enum AuthEnum {
  SIGN_IN = 'sign-in',
  SIGN_UP = 'sign-up',
  VERIFICATION = 'verification',
  COMPLETE_REGISTRATION = 'complete-registration',
}

export type AuthFormFieldsValues = {
  email: string;
  password?: string;
  company: string;
  positionTitle: string;
  firstName: string;
  lastName: string;
  verificationCode?: string;
  industry: string;
  companyName?: string;
  revenue: string;
  address?: string;
  profile?: string;
  yearFounded?: string;
  companySize?: string;
  url?: string;
  imageRef?: string;
};

type CompleteRegistrationFormFieldsValues = {
  firstName: string;
  lastName: string;
  industry?: string;
};

export type FormFieldsValues =
  | AuthFormFieldsValues
  | CompleteRegistrationFormFieldsValues;

export type FormField = {
  name: string;
  label: string;
  type?: string;
  sm?: number;
  xs?: number;
  testId?: string;
};

export type AuditAttributes = {
  createdAt?: Date;
  updatedAt?: Date;
};

export type OnboardingConfigItem = {
  id: number;
  name?: string;
  text?: string;
  hidden?: boolean;
  completed?: boolean;
};

export type User = AuditAttributes & {
  id?: any;
  email: string;
  isAdmin: boolean;
  company?: string;
  positionTitle?: string;
  industry?: string;
  firstName?: string;
  lastName?: string;
  idToken?: string;
  accessToken?: string;
  hasCompletedRegistration?: boolean;
  isPaidMember?: boolean;
  userType?: string;
  Companies?: Array<Company>;
  reviews?: Array<BasicReview>;
  companySize?: string;
  onboardingConfig?: Array<OnboardingConfigItem>;
  subscription?: Subscription;
  deepDivesViewedInSubPeriod?: number;
  deepDivesSubmittedInSubPeriod?: number;
  deepDivesAvailableInSubPeriod?: number;
  planDeepDiveReviewLimit?: number;
  maxConsumableDeepDivesInSubPeriod?: number;
  planSlug?: 'team' | 'individual' | 'free' | null;
};

export type Company = AuditAttributes & {
  reviewsCount?: number;
  id?: number;
  yearFounded?: string;
  email?: string;
  companyName?: string;
  companySize?: string;
  city?: string;
  profile?: string;
  state?: string;
  url?: string;
  imageRef?: string;
  idToken?: string;
  country?: string;
  hasCompletedRegistration?: boolean;
  isPaidMember?: boolean;
  revenue?: string;
  userType?: UserType;
  clarity?: string;
  transparency?: string;
  responsiveness?: string;
  understanding?: string;
  averageRating?: string;
  reviews?: Array<BasicReview>;
  stockTicker?: string;
  isApproved?: boolean;
};

export enum SubscriptionPlan {
  FREE = 'free',
  INDIVIDUAL = 'individual',
  TEAM = 'team',
}

export enum SubscriptionStatus {
  ACTIVE = 'active',
  CANCELLED = 'cancelled',
  EXPIRED = 'expired',
}

export enum SubscriptionTerm {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
  FOREVER = 'forever',
  CUSTOM = 'custom',
}

export type Subscription = AuditAttributes & {
  id?: number;
  userId: number;
  startDate?: Date;
  endDate?: Date;
  plan: SubscriptionPlan;
  status: SubscriptionStatus;
  term: SubscriptionTerm;
};

export type IdTokenData = {
  email: string;
  'cognito:username': string;
};

export const UserPoolData = {
  UserPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID as string,
  ClientId: import.meta.env.VITE_COGNITO_CLIENT_ID as string,
};

export const SIGN_UP_FORM_FIELDS: Array<FormField> = [
  {
    name: 'firstName',
    label: 'First Name',
    type: 'text',
    sm: 6,
    xs: 12,
    testId: 'sign-up-firstName',
  },
  {
    name: 'lastName',
    label: 'Last Name',
    type: 'text',
    sm: 6,
    xs: 12,
    testId: 'sign-up-lastName',
  },
  {
    name: 'email',
    label: 'Email',
    type: 'email',
    xs: 12,
    testId: 'sign-up-email',
  },
  {
    name: 'company',
    label: 'Current Company',
    type: 'text',
    xs: 12,
    testId: 'sign-up-company',
  },
  {
    name: 'positionTitle',
    label: 'Position Title',
    type: 'text',
    xs: 12,
    testId: 'sign-up-positionTitle',
  },
  {
    name: 'companySize',
    label: 'Company Size',
    type: 'text',
    xs: 12,
    testId: 'sign-up-companySize',
  },
  {
    name: 'industry',
    label: 'Industry',
    type: 'text',
    xs: 12,
    testId: 'sign-up-industry',
  },
  {
    name: 'password',
    label: 'Password',
    type: 'password',
    xs: 12,
    testId: 'sign-up-password',
  },
];

export const VERIFICATION_FORM_FIELDS: Array<FormField> = [
  {
    name: 'verificationCode',
    label: 'Verification Code',
    type: 'text',
    xs: 12,
  },
];

export const SIGN_IN_FORM_FIELDS: Array<FormField> = [
  {
    name: 'email',
    label: 'Email',
    type: 'email',
    xs: 12,
    testId: 'sign-in-email',
  },
  {
    name: 'password',
    label: 'Password',
    type: 'password',
    xs: 12,
    testId: 'sign-in-password',
  },
];

export const COMPANY_SIZE_OPTIONS = [
  {
    name: 'Under 50',
  },
  {
    name: '51 - 250',
  },
  {
    name: '251 - 1000',
  },
  {
    name: '1001 - 5000',
  },
  {
    name: '5001 - 10,000',
  },
  {
    name: '10, 001 - 50,000',
  },
  {
    name: 'Over 50,000',
  },
];

export const INDUSTRY_SELECT_OPTIONS = [
  {
    name: 'Accounting and Finance',
  },
  {
    name: 'Applicant Tracking Software (ATS)',
  },
  {
    name: 'Artificial Intelligence',
  },
  {
    name: 'Back Office Management',
  },
  {
    name: 'Business Intelligence and Analytics',
  },
  {
    name: 'Business Process Management',
  },
  {
    name: 'CAD & PLM',
  },
  {
    name: 'Cloud Computing',
  },
  {
    name: 'Collaboration & Productivity',
  },
  {
    name: 'Communication Technology',
  },
  {
    name: 'Content Management',
  },
  {
    name: 'Customer Data Platform (CDP)',
  },
  {
    name: 'Customer Relationship Management (CRM)',
  },
  {
    name: 'Customer Support and Help Desk',
  },
  {
    name: 'Cybersecurity',
  },
  {
    name: 'Data Privacy',
  },
  {
    name: 'Document Management',
  },
  {
    name: 'E-Commerce',
  },
  {
    name: 'Employee Performance Management',
  },
  {
    name: 'Engineering and Development Tools',
  },
  {
    name: 'Enterprise Resource Planning',
  },
  {
    name: 'Expense Management',
  },
  {
    name: 'Field Service Management',
  },
  {
    name: 'FinTech',
  },
  {
    name: 'Governance, Risk & Compliance',
  },
  {
    name: 'Human Resource Management (HRM)',
  },
  {
    name: 'Identity and Access Management',
  },
  {
    name: 'IoT Management',
  },
  {
    name: 'IT Infrastructure',
  },
  {
    name: 'IT Management',
  },
  {
    name: 'Learning Management Software (LMS)',
  },
  {
    name: 'Legal Tech',
  },
  {
    name: 'MarTech',
  },
  {
    name: 'Procurement and Vendor Management',
  },
  {
    name: 'Sales Tools',
  },
  {
    name: 'Supply Chain & Logistics',
  },
];
