import styled from 'styled-components';
import { P, screenLgMin, screenMdMin } from '../styles';
import { StyledLink } from './Link';
import { preloginPaths } from '../constants';
import { useLocation } from 'react-router-dom';
import { PublicPageImage } from './Navbar';
import kandirLogo from '../../assets/images/kandir_logo.png';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 var(--spacing-md);
  width: 100%;
`;

const ImageWrapper = styled.div`
  margin-right: var(--spacing-sm);
  margin-top: var(--spacing-sm);
`;

const DividerLine = styled.div`
  height: 38px;
  width: 2px;
  background-color: var(--color-white);
  margin: var(--spacing-sm);
`;

const DividerLineBottom = styled.div`
  text-align: center;
  align-items: center;
  height: 1px;
  width: 100%;
  margin: auto;
  background-color: var(--color-grey-light);
  margin-top: var(--spacing-lg);
`;

const TextWrapper = styled.div`
  margin: var(--spacing-sm) 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media ${screenMdMin} {
    font-size: 16px;
  }
`;

const NavLinks = styled.div`
  display: flex;
  margin-top: var(--spacing-md);
`;

const StyledLinkCustom = styled(StyledLink)`
  color: var(--color-white);
  margin: 0 var(--spacing-xs);
  margin-top: var(--spacing-sm);
  font-size: 12px;
  @media ${screenMdMin} {
    font-size: 16px;
    margin: 0 var(--spacing-md);
    margin-top: 15px;
  }
`;

const StyledText = styled(P)`
  margin-top: var(--spacing-sm);
  margin-left: var(--spacing-lg);
  font-size: 12px;
  @media ${screenMdMin} {
    margin-left: 0;
    font-size: 16px;
    margin-top: var(--spacing-md);
  }
`;

const StyledBox = styled.div`
  background-color: #0f2867;
  padding: var(--spacing-md);
  width: 100%;
  @media ${screenMdMin} {
    padding: var(--spacing-md);
  }
`;

const Footer = () => {
  const location = useLocation();

  return (
    <>
      {preloginPaths.includes(location.pathname) && (
        <StyledBox>
          <Container>
            <>
              <TextWrapper>
                <ImageWrapper>
                  <PublicPageImage src={kandirLogo} />
                </ImageWrapper>
                <NavLinks>
                  <StyledLinkCustom to="company-search">
                    Company Search
                  </StyledLinkCustom>
                  <DividerLine />
                  <StyledLinkCustom to="sign-in">Login</StyledLinkCustom>
                  <DividerLine />
                  <StyledLinkCustom to="sign-up">Sign Up</StyledLinkCustom>
                  <DividerLine />
                  <StyledLinkCustom to="privacy">
                    Privacy Policy
                  </StyledLinkCustom>
                  <DividerLine />
                  <StyledLinkCustom to="terms">Terms of Use</StyledLinkCustom>
                </NavLinks>
              </TextWrapper>
              <DividerLineBottom />
              <TextWrapper>
                <StyledText style={{ color: 'white' }}>
                  Copyright © 2024 Kandir Inc. All Rights Reserved.
                </StyledText>
              </TextWrapper>
            </>
          </Container>
        </StyledBox>
      )}
    </>
  );
};
export default Footer;
