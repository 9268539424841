import { useContext, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import LoadingProgress from '../../../shared/components/LoadingProgress';
import CustomSnackbar from '../../../shared/components/Snackbar';
import { UserContext } from '../../../shared/contexts/UserContext';
import { Company } from '../../authentication/types/types.auth';
import ReviewDialog from '../../review/components/ReviewDialog';
import CompanyProfileBanner from './CompanyProfileBanner';
import CompanyProfileHeader from './CompanyProfileHeader';
import CompanyProfileRatings from './CompanyProfileReviews';
import { useReviewContext } from '../../review/context/ReviewContext';
import ReviewUnlockModal from '../../review/components/DeepDiveReviewCountModal';
import { BasicReview } from '../../../shared/domain/review';

const Container = styled.div`
  max-width: 100%;
  padding: var(--spacing-md);
`;

const CompanyProfile = () => {
  const [currentCompany, setCurrentCompany] = useState<Company>();
  const [openReviewDialog, setOpenReviewDialog] = useState(false);
  const [openReviewUnlockModal, setOpenReviewUnlockModal] = useState(false);
  const [selectedReviewId, setSelectedReviewId] = useState<number>();

  const { name } = useParams();

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const planMaxed = searchParams.get('planMaxed');

  const { user, fetchDeepDiveReviewCount } = useContext(UserContext);

  const {
    snackbarConfig,
    setSnackbarConfig,
    allCompanyReviews,
    setReviewId,
    fetchAllReviews,
  } = useReviewContext();

  const reviewDialogChange = () => {
    setOpenReviewDialog(true);
  };

  const handleModalProceed = () => {
    navigate(`/review-reader/${name}/${selectedReviewId}`);
  };

  const handleSnackBarClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  const getCompanyInfo = async () => {
    try {
      const res = await axios.get(
        `${import.meta.env.VITE_API_BASE_URL}/company/${name}`
      );
      setCurrentCompany(res.data);
    } catch (error) {
      setSnackbarConfig({
        open: true,
        type: 'error',
        message: 'Server error, please try again later',
      });
    }
  };

  const isCompanyFollowed =
    user?.Companies?.map((company: Company) => company.companyName).includes(
      currentCompany?.companyName
    ) || false;

  const followCompany = async () => {
    if (!user) {
      setSnackbarConfig({
        message: 'You must be logged in to use this feature.',
        open: true,
        type: 'error',
      });
      return;
    }
    try {
      const updateCompanyInput = {
        companyId: currentCompany?.id,
        userId: user.id,
      };

      await axios.post(
        isCompanyFollowed
          ? `${import.meta.env.VITE_API_BASE_URL}/users/unfollow-company`
          : `${import.meta.env.VITE_API_BASE_URL}/users/follow-company`,
        updateCompanyInput,
        {
          headers: {
            Authorization: user?.idToken,
          },
        }
      );
      setSnackbarConfig({
        message: `${currentCompany?.companyName} Successfully ${
          isCompanyFollowed ? 'Unfollowed' : 'Followed'
        }.`,
        open: true,
        type: 'success',
      });
    } catch (error) {
      setSnackbarConfig({
        message: 'An error has occurred, please try again later.',
        open: true,
        type: 'error',
      });
    }
  };

  const handleOpenReviewDialog = (fetchReviewId?: number) => {
    setReviewId(fetchReviewId || 0);
    setOpenReviewDialog(true);
  };

  const handleDeepDiveClick = (review: BasicReview) => {
    if (
      user?.planDeepDiveReviewLimit &&
      !review.viewed &&
      user?.id !== review.userId &&
      user?.planSlug !== 'team'
    ) {
      setSelectedReviewId(review.id);
      setOpenReviewUnlockModal(true);
    } else {
      navigate(`/review-reader/${name}/${review.id}`);
    }
  };

  useEffect(() => {
    getCompanyInfo();
  }, []);

  useEffect(() => {
    if (!openReviewDialog && currentCompany?.id) {
      fetchAllReviews(currentCompany.id);
      fetchDeepDiveReviewCount();
    }
  }, [openReviewDialog, currentCompany?.id]);

  useEffect(() => {
    fetchAllReviews(currentCompany?.id || 0);
  }, [user?.idToken]);

  useEffect(() => {
    if (planMaxed) {
      setOpenReviewUnlockModal(true);
    }
  }, [planMaxed]);

  if (!currentCompany) {
    return <LoadingProgress />;
  }

  return (
    <Container>
      <ReviewDialog
        open={openReviewDialog}
        setOpen={setOpenReviewDialog}
        companyName={currentCompany.companyName || ''}
        companyId={currentCompany.id || 0}
      />
      <CompanyProfileHeader
        name={name || ''}
        openReviewDialog={reviewDialogChange}
        followCompany={followCompany}
        isCompanyFollowed={isCompanyFollowed}
      />
      <CompanyProfileBanner currentCompany={currentCompany} />
      <Grid container spacing={5} sx={{ mt: 1 }}>
        <CompanyProfileRatings
          handleOpenReviewDialog={handleOpenReviewDialog}
          title={`${name} Ratings`}
          reviews={allCompanyReviews || []}
          handleDeepDiveClick={handleDeepDiveClick}
        />
      </Grid>

      {openReviewUnlockModal && (
        <ReviewUnlockModal
          open={openReviewUnlockModal}
          onCancel={() => setOpenReviewUnlockModal(false)}
          maxConsumableDeepDivesInSubPeriod={
            user?.maxConsumableDeepDivesInSubPeriod || 0
          }
          consumedDeepDives={user?.deepDivesViewedInSubPeriod || 0}
          cancelText="Back to Company Profile"
          onProceed={handleModalProceed}
        />
      )}
      <CustomSnackbar config={snackbarConfig} setOpen={handleSnackBarClose} />
    </Container>
  );
};

export default CompanyProfile;
