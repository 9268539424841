import { useState } from 'react';
import { Box, Checkbox, Container, Grid } from '@mui/material';
import { LockOutlined } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import AuthFormFields from './UserAuthFormFields';
import {
  StyledAvatarWrapper,
  StyledFormContainer,
} from '../../../shared/styles/FormStyles';
import { H2, P } from '../../../shared/styles';
import { FormField, AuthEnum, FormFieldsValues } from '../types/types.auth';
import Button from '../../../shared/components/Buttons/Button';

type FormWrapperProps = {
  handleFormFieldChange: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    selectedOptions?: any
  ) => void;
  formFields: Array<FormField>;
  authType?: AuthEnum;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  title: string;
  buttonText: string;
  defaultValues?: FormFieldsValues;
  handleResendVerificationCode?: () => void;
  userNotConfirmed?: boolean;
};

const TermsWrapper = styled.div`
  display: flex;
  margin: var(--spacing-sm) 0;
  margin-left: -12px;
`;

const ButtonWrapper = styled.div`
  text-align: center;
  margin: var(--spacing-md);
`;

const StyledLink = styled(Link)`
  color: var(--color-black);
  margin-bottom: var(--spacing-sm);
`;

const StyledText = styled(P)`
  margin-bottom: var(--spacing-md);
`;

const LinkWrapper = styled.div`
  flex-direction: column;
  display: flex;
  text-align: right;
`;

const FormWrapper = (props: FormWrapperProps) => {
  const {
    handleFormFieldChange,
    authType,
    formFields,
    handleSubmit,
    title,
    buttonText,
    defaultValues,
    handleResendVerificationCode,
    userNotConfirmed,
  } = props;

  const [checked, setChecked] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const navigate = useNavigate();

  return (
    <Container
      component="main"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
        textAlign: 'center',
      }}
    >
      <StyledFormContainer>
        <StyledAvatarWrapper>
          <LockOutlined />
        </StyledAvatarWrapper>
        <H2>{title}</H2>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginTop: 'var(--spacing-md)',
          }}
        >
          {authType === AuthEnum.VERIFICATION && (
            <StyledText>
              A verification code has been sent to the email address you
              registered with. Please enter your verification code below. &nbsp;
            </StyledText>
          )}
          <AuthFormFields
            handleFormFieldChange={handleFormFieldChange}
            formFields={formFields}
            defaultValues={defaultValues}
            authType={authType}
          />
          {authType === AuthEnum.SIGN_UP && (
            <TermsWrapper>
              <Checkbox
                data-testid="sign-up-terms-agree-checkbox"
                checked={checked}
                onChange={handleChange}
              />
              <P style={{ marginTop: 'var(--spacing-sm)' }}>
                Please read and agree to our{' '}
                <StyledLink target="_blank" to="/privacy">
                  Privacy Policy
                </StyledLink>{' '}
                and our{' '}
                <StyledLink to="/terms" target="_blank">
                  Terms of Use
                </StyledLink>{' '}
              </P>
            </TermsWrapper>
          )}
          <ButtonWrapper>
            <Button testId="auth-submit-button" size="medium" type="submit">
              {buttonText}
            </Button>
          </ButtonWrapper>
          {authType === AuthEnum.VERIFICATION ||
            (userNotConfirmed && (
              <Button
                size="medium"
                onClick={() => {
                  handleResendVerificationCode &&
                    handleResendVerificationCode();
                  navigate('/verification');
                }}
              >
                Resend code to Email
              </Button>
            ))}
          <Grid container justifyContent="flex-end">
            <Grid item>
              {authType === AuthEnum.SIGN_UP ? (
                <>
                  <StyledLink to="/sign-in">
                    Already have an account? Sign In
                  </StyledLink>
                </>
              ) : authType === AuthEnum.SIGN_IN ? (
                <LinkWrapper>
                  <StyledLink to="/sign-up">
                    Don't have an account yet? Sign Up
                  </StyledLink>
                  <StyledLink to="/forgot-password">
                    Forgot Password?
                  </StyledLink>
                </LinkWrapper>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Box>
      </StyledFormContainer>
    </Container>
  );
};

export default FormWrapper;
