import React from 'react';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import FlagIcon from '@mui/icons-material/Flag';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PersonIcon from '@mui/icons-material/Person';
import StarIcon from '@mui/icons-material/Star';
import BusinessIcon from '@mui/icons-material/Business';
import MoneyIcon from '@mui/icons-material/AttachMoney';
import TimelineIcon from '@mui/icons-material/Timeline';
import EventIcon from '@mui/icons-material/Event';
import GavelIcon from '@mui/icons-material/Gavel';
import SyncIcon from '@mui/icons-material/Sync';
import PeopleIcon from '@mui/icons-material/People';
import ComputerIcon from '@mui/icons-material/Computer';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DevicesIcon from '@mui/icons-material/Devices';
import ChatIcon from '@mui/icons-material/Chat';
import EditIcon from '@mui/icons-material/Edit';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import InfoIcon from '@mui/icons-material/Info';
import VisibilityIcon from '@mui/icons-material/Visibility';
import styled from 'styled-components';

export enum IconName {
  Lock = 'Lock',
  LockOpen = 'LockOpen',
  Flag = 'Flag',
  MonetizationOn = 'MonetizationOn',
  EventNote = 'EventNote',
  AccessTime = 'AccessTime',
  CheckCircle = 'CheckCircle',
  Cancel = 'Cancel',
  CalendarToday = 'CalendarToday',
  Person = 'Person',
  Star = 'Star',
  Business = 'Business',
  Money = 'Money',
  Timeline = 'Timeline',
  Event = 'Event',
  Gavel = 'Gavel',
  Sync = 'Sync',
  People = 'People',
  Computer = 'Computer',
  Devices = 'Devices',
  Chat = 'Chat',
  Edit = 'Edit',
  Arrow = 'Arrow',
  ChatBubble = 'ChatBubble',
  FormatQuote = 'FormatQuote',
  Info = 'Info',
  Visibility = 'Visibility',
}

type DynamicIconProps = {
  iconName: IconName;
  size?: 'small' | 'medium' | 'large';
  usePrimaryColor?: boolean;
};

const StyledIcon = styled.div<{ size: 'small' | 'medium' | 'large' }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white svg {
    font-size: ${(props) => {
      switch (props.size) {
        case 'small':
          return '16px';
        case 'medium':
          return '24px';
        case 'large':
          return '42px';
        default:
          return '24px';
      }
    }};
  }
`;

const DynamicIcon: React.FC<DynamicIconProps> = ({
  iconName,
  usePrimaryColor,
  size = 'medium',
}) => {
  const iconMap = {
    Lock: LockIcon,
    LockOpen: LockOpenIcon,
    Flag: FlagIcon,
    MonetizationOn: MonetizationOnIcon,
    EventNote: EventNoteIcon,
    AccessTime: AccessTimeIcon,
    CheckCircle: CheckCircleIcon,
    Cancel: CancelIcon,
    CalendarToday: CalendarTodayIcon,
    Person: PersonIcon,
    Star: StarIcon,
    Business: BusinessIcon,
    Money: MoneyIcon,
    Timeline: TimelineIcon,
    Event: EventIcon,
    Gavel: GavelIcon,
    Sync: SyncIcon,
    People: PeopleIcon,
    Computer: ComputerIcon,
    Devices: DevicesIcon,
    Chat: ChatIcon,
    Edit: EditIcon,
    Arrow: ArrowForwardIcon,
    ChatBubble: ChatBubbleOutlineIcon,
    FormatQuote: FormatQuoteIcon,
    Info: InfoIcon,
    Visibility: VisibilityIcon,
  };

  const IconComponent = iconMap[iconName];
  return IconComponent ? (
    <StyledIcon size={size}>
      <IconComponent sx={usePrimaryColor ? { color: '#2b90e3' } : undefined} />
    </StyledIcon>
  ) : null;
};

export default DynamicIcon;
