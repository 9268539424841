import React, { ReactNode } from 'react';
import MuiButton from '@mui/material/Button';
import styled from 'styled-components';

interface BasicButtonProps {
  children: ReactNode;
  size?: 'small' | 'medium' | 'large';
  allowBreakLine?: boolean;
  onClick?: () => void;
  testId?: string;
  whiteTheme?: boolean;
  type?: 'button' | 'submit' | 'reset';
}

const StyledButton = styled(MuiButton)<{
  size?: 'small' | 'medium' | 'large';
  allowBreakLine?: boolean;
  whiteTheme?: boolean;
}>`
  text-transform: none;
  height: ${(props) =>
    props.size === 'small'
      ? '32px'
      : props.size === 'medium'
        ? '42px'
        : '52px'};
  width: ${(props) =>
    props.allowBreakLine
      ? 'auto'
      : props.size === 'small'
        ? '100px'
        : props.size === 'medium'
          ? '130px'
          : '160px'};
  font-size: ${(props) =>
    props.size === 'small'
      ? '13px'
      : props.size === 'medium'
        ? '15px'
        : '17px'};
  font-weight: 600;
  border-radius: 8px;
  white-space: ${(props) => (props.allowBreakLine ? 'normal' : 'nowrap')};
  min-width: ${(props) => (props.allowBreakLine ? 'auto' : 'fit-content')};
  text-align: center;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  box-shadow: ${(props) =>
    props.whiteTheme
      ? '0px 4px 8px rgba(0, 0, 0, 0.1)'
      : '0px 4px 8px rgba(0, 0, 0, 0.2)'};

  background: ${(props) => (props.whiteTheme ? '#FFFFFF' : 'rgb(0, 158, 219)')};
  color: ${(props) => (props.whiteTheme ? '#000000' : '#FFFFFF')};
  border: ${(props) => (props.whiteTheme ? '1.5px solid #E0E0E0' : 'none')};

  &:hover {
    background: ${(props) =>
      props.whiteTheme ? '#F8F8F8' : 'rgb(0, 158, 219)'};
    box-shadow: ${(props) =>
      props.whiteTheme
        ? '0px 6px 12px rgba(0, 0, 0, 0.15)'
        : '0px 6px 12px rgba(0, 0, 0, 0.3)'};
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(1px);
    box-shadow: none;
  }
`;

const Button = ({
  children,
  size,
  onClick,
  type,
  whiteTheme,
  allowBreakLine = false,
  testId,
}: BasicButtonProps) => {
  return (
    <StyledButton
      variant="contained"
      size={size}
      allowBreakLine={allowBreakLine}
      whiteTheme={whiteTheme}
      onClick={onClick}
      data-testid={testId}
      type={type}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
