import { RatingSummary } from '../../../shared/domain/review';
import {
  CurrentReadableReview,
  ReviewSection,
  ReviewAnswer,
} from '../types/types';

type LabelMeta = {
  label: string;
  iconGroup: string;
  sortOrder: number;
  section: string;
};

type LabelMap = Record<string, LabelMeta>;

export const formatReviewData = (reviewData: any): CurrentReadableReview => {
  const labels: LabelMap = reviewData.labels;

  const reviewSections: Array<
    keyof Omit<
      CurrentReadableReview,
      'reviewBasic' | 'companyName' | 'createdAt'
    >
  > = [
    'ReviewEvaluation',
    'ReviewLegal',
    'ReviewNegotiation',
    'ReviewPurchaseDetail',
    'ReviewRfp',
  ];

  const formattedReview: Partial<CurrentReadableReview> = {};

  reviewSections.forEach((sectionKey) => {
    if (reviewData[sectionKey] && typeof reviewData[sectionKey] === 'object') {
      const sectionEntries = Object.entries(reviewData[sectionKey])
        .filter(([key]) => labels[key]) // only include if label exists
        .sort((a, b) => {
          const aOrder = labels[a[0]]?.sortOrder ?? Infinity;
          const bOrder = labels[b[0]]?.sortOrder ?? Infinity;
          return aOrder - bOrder;
        });

      formattedReview[sectionKey] = sectionEntries.reduce<ReviewSection>(
        (acc, [key, value]) => {
          const meta = labels[key];
          acc[key] = {
            label: meta.label,
            iconGroup: meta.iconGroup,
            answer: Array.isArray(value)
              ? value
              : typeof value === 'string' || typeof value === 'number'
                ? value
                : null,
          };
          return acc;
        },
        {}
      );
    }
  });

  const excludedKeys = [...reviewSections, 'labels', 'ratings'];

  const basicReview: ReviewSection = Object.entries(
    reviewData
  ).reduce<ReviewSection>((acc, [key, value]) => {
    if (!excludedKeys.includes(key) && labels[key]) {
      acc[key] = {
        label: labels[key].label,
        iconGroup: labels[key].iconGroup,
        answer: Array.isArray(value)
          ? value
          : typeof value === 'string' || typeof value === 'number'
            ? value
            : null,
      };
    }
    return acc;
  }, {});

  if (reviewData.ratings) {
    basicReview.ratings = reviewData.ratings.map(
      (rating: RatingSummary): ReviewAnswer => ({
        label: rating.type,
        iconGroup: labels[rating.type]?.iconGroup || 'default',
        answer: rating.rating,
      })
    );
  }

  return {
    companyName: reviewData.companyName || '',
    createdAt: reviewData.createdAt || '',
    reviewBasic: basicReview,
    ...formattedReview,
  };
};
