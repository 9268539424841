import styled from 'styled-components';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../contexts/UserContext';
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, IconButton } from '@mui/material';
import { UserPoolData } from '../../scenes/authentication/types/types.auth';
import SideDrawer from './SideDrawer';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { screenLgMin } from '../styles';
import { preloginPaths } from '../constants';
import { useResponsive } from '../hooks/useResponsive';
import kandirLogo from '../../assets/images/kandir_logo.png';

export type NavBarProps = {
  setIsNavOpen: (isOpen: boolean) => void;
  isNavOpen: boolean;
  transparent: boolean;
};

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 auto;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
`;

const StyledIconButton = styled(IconButton)`
  background-color: var(--color-grey);
  &:hover {
    background-color: var(--color-grey-light);
  }
`;

const StyledLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  height: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  text-align: right;
  float: right;
  justify-content: flex-end;
  gap: var(--spacing-xs);
  overflow: hidden;
  white-space: nowrap;
  flex-wrap: nowrap;
  @media ${screenLgMin} {
    margin-top: 0;
  }
`;

const StyledButton = styled(Button)`
  margin: 0 var(--spacing-xs);
  color: var(--color-white);
  border-radius: 30px;
  border: 1px solid var(--color-white);
  height: 40px;
  font-size: 12px;
  text-transform: capitalize;
  white-space: nowrap;
  margin-right: var(--spacing-md);
  @media ${screenLgMin} {
    font-size: 14px;
    min-width: 150px;
  }
`;

const NavigationContainer = styled.div`
  padding: var(--spacing-md);
  background-color: var(--color-white);
  min-height: 90px;
  display: flex;
  align-items: center;
  position: relative;
`;

const WelcomePageImage = styled.img`
  width: 50px;
  height: auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

export const PublicPageImage = styled.img`
  width: 60px;
  height: auto;
  margin-left: var(--spacing-xs);
  @media ${screenLgMin} {
    margin-left: var(--spacing-md);
    width: 75px;
  }
`;

const TopNav = styled.div<{ transparent?: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  padding: var(--spacing-md) 0;
  background-color: ${({ transparent }) =>
    transparent ? 'rgba(0, 0, 0, 0)' : '#0f2867'};
  position: absolute;
  top: 0;
  z-index: 1000;
`;

const Navbar = (props: NavBarProps) => {
  const { setIsNavOpen, isNavOpen } = props;

  const { user, setUser } = useContext(UserContext);
  const { isMobile } = useResponsive();
  const location = useLocation();
  const navigate = useNavigate();

  const [isTransparent, setIsTransparent] = useState(location.pathname === '/');

  useEffect(() => {
    setIsTransparent(location.pathname === '/');
  }, [location.pathname]);

  // TODO: move this to Authentication
  const cognitoUser =
    user &&
    new CognitoUser({
      Username: user?.email || '',
      Pool: new CognitoUserPool(UserPoolData),
    });

  const logOut = () => {
    cognitoUser?.signOut();
    setUser(null);
    navigate('/');
    setIsNavOpen(false);
  };

  return (
    <>
      {user && !preloginPaths.includes(location.pathname) && (
        <>
          <NavigationContainer role="app-nav">
            <StyledIconButton
              onClick={() => setIsNavOpen(!isNavOpen)}
              sx={{ mr: 2, ...(isNavOpen && { display: 'none' }) }}
            >
              <MenuIcon />
            </StyledIconButton>
            {!isNavOpen && <WelcomePageImage src={kandirLogo} />}
          </NavigationContainer>
          <SideDrawer
            isOpen={isNavOpen}
            setIsOpen={setIsNavOpen}
            logOut={logOut}
          />
        </>
      )}
      {preloginPaths.includes(location.pathname) && (
        <TopNav role="public-nav" transparent={isTransparent}>
          <Container>
            <StyledLink to="/">
              <PublicPageImage src={kandirLogo} />
            </StyledLink>
            <ButtonWrapper>
              {!isMobile && (
                <>
                  <StyledButton onClick={() => navigate('/company-search')}>
                    Company Search
                  </StyledButton>
                  <StyledButton onClick={() => navigate('/pricing')}>
                    Pricing
                  </StyledButton>
                  <StyledButton onClick={() => navigate('/sign-up')}>
                    Sign Up
                  </StyledButton>
                </>
              )}
              <StyledButton onClick={() => navigate('/sign-in')}>
                Login
              </StyledButton>
            </ButtonWrapper>
          </Container>
        </TopNav>
      )}
    </>
  );
};

export default Navbar;
