import { PricingCardData } from '../types';

export const pricingCards: PricingCardData[] = [
  {
    title: 'Free',
    tagline: 'Leave reviews to unlock Kandir Intelligence',
    features: [
      'Access to Basic reviews for all companies',
      "For each Deep Dive review you submit, you'll unlock one additional Deep Dive review to consume",
    ],
    buttonText: 'Free',
    slug: 'free',
  },
  {
    title: 'Individual',
    tagline: 'Industry-leading intelligence',
    features: [
      'Access to Basic reviews for all companies',
      'Access to 25 Deep Dive reviews per term',
      "For each Deep Dive review you submit, you'll unlock one additional Deep Dive review to consume",
    ],
    buttonText: '$977 per year',
    slug: 'individual',
  },
  {
    title: 'Team',
    tagline: 'Comprehensive access for sales organizations',
    features: [
      'Everything in Individual',
      'Access to Unlimited Deep Dive reviews',
      'One custom pipeline review target',
      '"Ask the Seller" access',
    ],
    buttonText: 'Request a Quote',
    slug: 'team',
  },
];
