import { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { P } from '../../../shared/styles';

const WelcomeModal = (props: DialogProps) => {
  const [open, setOpen] = useState(props.open);
  const handleClose = () => {
    setOpen(false);

    // Call the passed in onClose function once the modal is dismissed
    if (props.onClose) {
      props.onClose({}, 'escapeKeyDown');
    }
  };

  const descriptionElementRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth={props.maxWidth}
      data-testid="welcome-modal"
    >
      <DialogTitle id="scroll-dialog-title">Welcome to Kandir!</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          <P>
            Welcome to <strong>Kandir</strong>, the ultimate place for sales
            pros who want the real scoop on closing monster deals. No fluff, no
            corporate jargon—just straight-up intel from sellers who’ve been in
            the trenches.
          </P>
          &nbsp;
          <P>
            Kandir runs on a <strong>give-to-get</strong> model. We’ve got two
            types of reviews:
          </P>
          &nbsp;
          <P>
            💡 <strong>Basic Reviews</strong> – Solid, high-level advice on how
            to work your magic with a prospect.
          </P>
          <P>
            🕵️‍♂️ <strong>Deep Dives</strong> – The good stuff. We’re talking
            procurement strategies, legal roadblocks, security review
            nightmares, and all the gritty details you actually need to get the
            deal done.
          </P>
          &nbsp;
          <P>
            Since you’re new, we’re hooking you up with{' '}
            <strong>one free Deep Dive</strong>. Just search for a company
            you’re chasing, unlock the intel, and start selling smarter.
          </P>
          <P>
            Want more? Easy. Drop your own Deep Dive on a deal you’ve worked,
            and we’ll give you <strong>another one for free</strong>. It’s like
            karma, but for sales.
          </P>
          &nbsp;
          <P>
            Too busy to leave a review? No problem—just hit{' '}
            <strong>“Add More Reviews”</strong> and check out our pricing page.
          </P>
          &nbsp;
          <P>A few final tips:</P>
          &nbsp;
          <P>
            🔍 Can’t find a company? Just type their name into search and you’ll
            see a link to create a new company profile. Boom. Problem solved.
          </P>
          <P>
            👀 Hit <strong>“Follow”</strong> on a company’s profile page, and
            we’ll send you an email whenever new reviews drop.
          </P>
          <P>
            🏢 <strong>Team Access = Unlimited Deep Dives</strong>. Get the
            whole squad on Kandir and unlock even more features that make
            closing easier.
          </P>
          &nbsp;
          <P>
            That’s it. <strong>Learn. Win. Make it rain.</strong>
          </P>
          &nbsp;
          <P>
            <strong>Happy selling</strong>.
          </P>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} data-testid="welcome-modal-close-button">
          Let's Go! 🚀
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WelcomeModal;
