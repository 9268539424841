import React from 'react';
import { Typography } from '@mui/material';
import styled from 'styled-components';
import DynamicIcon, { IconName } from '../../../shared/components/Icon';
import Button from '../../../shared/components/Buttons/Button';

type SectionHeaderReaderProps = {
  headerSubtext: string;
  scrollToTop: () => void;
  iconName?: IconName;
};

const TopBannerContainer = styled.div`
  background-color: #2b90e3;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-sm);
  border-radius: var(--spacing-md);
`;

const SectionTitle = styled(Typography)`
  && {
    font-weight: bold;
    color: white;
    font-size: 1.4rem;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  gap: var(--spacing-xs);
`;

const SectionHeaderReader = (props: SectionHeaderReaderProps) => {
  const { headerSubtext, scrollToTop, iconName } = props;

  return (
    <TopBannerContainer>
      <HeaderWrapper>
        <DynamicIcon iconName={iconName || IconName.Business} />{' '}
        <SectionTitle>{headerSubtext}</SectionTitle>
      </HeaderWrapper>
      <Button size="small" whiteTheme onClick={scrollToTop}>
        Back to Top
      </Button>
    </TopBannerContainer>
  );
};

export default SectionHeaderReader;
