import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { P } from '../../../shared/styles';
import Button from '../../../shared/components/Buttons/Button';
import { UserContext } from '../../../shared/contexts/UserContext';
import PhaseInAnimation from '../../../shared/animations/PhaseInAnimation';
import { useNavigate } from 'react-router-dom';
import { MOBILE_BREAKPOINT } from '../../../shared/styles/Breakpoints';
import { Tooltip, Typography } from '@mui/material';
import Banner from '../../../shared/components/Banner';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  border-radius: var(--border-radius);
  background-color: var(--color-white);
  padding: var(--spacing-sm) var(--spacing-md);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: var(--spacing-sm);

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    flex-direction: column;
    height: auto;
    padding: var(--spacing-md);
    text-align: center;
  }
`;

const ProgressContainer = styled.div`
  display: flex;
  flex: 1;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: var(--border-radius);
  margin: 0 var(--spacing-md);
  position: relative;
  overflow: hidden;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    margin: var(--spacing-sm) 0;
    width: 100%;
    min-width: 150px;
  }
`;

const ProgressBar = styled.div<{ percentage: number }>`
  background: linear-gradient(to right, #ccdcf8, #6fa4f9);
  height: 100%;
  border-radius: 6px;
  transition: width 1.3s ease-in-out;
  width: ${(props) => props.percentage}%;
  min-width: ${(props) => (props.percentage > 0 ? '2px' : '0')};

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    height: 10px;
  }
`;

const Count = styled(P)`
  margin-right: var(--spacing-md);

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    margin: var(--spacing-sm) 0 var(--spacing-sm) 0;
    font-size: 14px;
  }
`;

const GiveToGetPrompt = () => {
  const { user } = useContext(UserContext);
  const [renderedPercentage, setRenderedPercentage] = useState(0);

  const navigate = useNavigate();
  const isDataLoaded = (user?.planDeepDiveReviewLimit ?? 0) > 0;
  const percentage = isDataLoaded
    ? Math.min(
        ((user?.deepDivesViewedInSubPeriod || 0) /
          (user?.maxConsumableDeepDivesInSubPeriod || 0)) *
          100,
        100
      )
    : 0;

  const hasEarnedBonusDeepDives = (): boolean => {
    return (
      (user?.maxConsumableDeepDivesInSubPeriod ?? 0) >
      (user?.planDeepDiveReviewLimit ?? 0)
    );
  };

  useEffect(() => {
    if (isDataLoaded) {
      setRenderedPercentage(percentage);
    }
  }, [isDataLoaded, percentage]);

  return (
    <PhaseInAnimation isDataLoaded={isDataLoaded}>
      {isDataLoaded && (
        <>
          <Container>
            <P bold>Deep Dive Reviews Consumed</P>
            <ProgressContainer>
              <ProgressBar percentage={renderedPercentage} />
            </ProgressContainer>
            <Count bold>
              <span data-testid="deep-dives-consumed-count">
                {user?.deepDivesViewedInSubPeriod}{' '}
              </span>
              of{' '}
              {hasEarnedBonusDeepDives() ? (
                <Tooltip
                  title={
                    "You've earned " +
                    user?.deepDivesSubmittedInSubPeriod +
                    ' bonus deep dive reviews by submitting that many yourself.  Thank you!'
                  }
                  arrow
                >
                  <span
                    style={{ color: 'green' }}
                    data-testid="max-consumable-deep-dives"
                  >
                    {user?.maxConsumableDeepDivesInSubPeriod ?? 0}
                  </span>
                </Tooltip>
              ) : (
                <span data-testid="max-consumable-deep-dives">
                  {user?.maxConsumableDeepDivesInSubPeriod ?? 0}
                </span>
              )}
            </Count>
            <Button onClick={() => navigate('/pricing')} size="small">
              Add More Reviews
            </Button>
          </Container>
          <Banner
            title="Give to Get:"
            description="For each deep dive review you leave on the platform, we’ll add one more for you to consume."
            onClick={() => {}}
            buttonText=""
            dismissible={false}
          />
        </>
      )}
    </PhaseInAnimation>
  );
};

export default GiveToGetPrompt;
