import { TextField } from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import LoadingProgress from '../../../shared/components/LoadingProgress';
import CustomSnackbar, {
  SnackBarConfig,
} from '../../../shared/components/Snackbar';
import CustomTable, {
  StyledActionButton,
} from '../../../shared/components/Tables/Table';
import { HeadCell } from '../../../shared/components/Tables/TableHeader';
import { UserContext } from '../../../shared/contexts/UserContext';
import { User } from '../../authentication/types/types.auth';
import { StyledAutocomplete, TableHeaderWrapper } from '../styles/admin.styles';
import InviteNewUserModal from './InviteNewUserModal';

type AdminUserTableProps = {
  tabChange: string;
};

const extractUserInfo = (user: User) => {
  const { firstName, lastName, company, email, createdAt, subscription } = user;
  return {
    firstName,
    lastName,
    company,
    email,
    subscriptionPlan: _.capitalize(subscription?.plan) || 'N/A',
    createdAt: dayjs(createdAt).format('MM/DD/YYYY'),
  };
};

const AdminUserTable = (props: AdminUserTableProps) => {
  const { tabChange } = props;
  const [loading, setLoading] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState<SnackBarConfig>();
  const [openInviteNewUserModal, setOpenInviteNewUserModal] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [userTableData, setUserTableData] = useState([]);

  const { user } = useContext(UserContext);

  const handleSnackBarClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  const getAllRegisteredUsers = async () => {
    if (!user?.idToken) return;
    setLoading(true);

    try {
      const res = await axios.get(
        `${import.meta.env.VITE_API_BASE_URL}/admin/user`,
        {
          headers: {
            Authorization: user?.idToken,
          },
        }
      );
      const tableData = res.data.map((user: User) => extractUserInfo(user));
      setUserTableData(tableData);
    } catch (error) {
      setSnackbarConfig({
        message: 'An error has occurred, please try again later',
        type: 'error',
        open: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const inviteNewUser = async (
    e: React.MouseEvent<HTMLElement>,
    email: string,
    name?: string
  ) => {
    e.preventDefault();
    setLoading(true);
    const input = {
      email: email,
      name: name ? name : 'Friends',
    };
    try {
      await axios.post(
        `${import.meta.env.VITE_API_BASE_URL}/admin/user/invite`,
        input,
        {
          headers: {
            Authorization: user?.idToken,
          },
        }
      );
      setSnackbarConfig({
        message: `Email successfully sent to ${email}!`,
        type: 'success',
        open: true,
      });
      setOpenInviteNewUserModal(false);
      setLoading(false);
    } catch (error) {
      setSnackbarConfig({
        message: 'An error has occurred, please try again later',
        type: 'error',
        open: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const filteredOptions = userTableData.filter(
    (user: User) =>
      user.email.toLowerCase().includes(searchInput.toLowerCase()) ||
      `${user.firstName} ${user.lastName}`
        .toLowerCase()
        .includes(searchInput.toLowerCase())
  );

  useEffect(() => {
    setLoading(true);
    getAllRegisteredUsers();
    setLoading(false);
  }, [user?.idToken, tabChange]);

  if (loading || !user?.idToken) {
    return <LoadingProgress />;
  }

  const headCells: HeadCell[] = [
    {
      id: 'firstName',
      showSort: true,
      disablePadding: true,
      label: 'First Name',
    },
    {
      id: 'lastName',
      showSort: true,
      disablePadding: false,
      label: 'Last Name',
    },
    {
      id: 'company',
      showSort: true,
      disablePadding: false,
      label: 'Company',
    },
    {
      id: 'email',
      showSort: true,
      disablePadding: false,
      label: 'Email',
    },
    {
      id: 'subscriptionPlan',
      showSort: true,
      disablePadding: false,
      label: 'Plan',
    },
    {
      id: 'createdAt',
      showSort: true,
      disablePadding: false,
      label: 'Date Created',
    },
    // {
    //   id: "Actions",
    //   showSort: false,
    //   disablePadding: false,
    //   label: "Actions",
    // },
  ];

  return (
    <>
      <TableHeaderWrapper>
        <StyledAutocomplete
          freeSolo
          disableClearable
          options={filteredOptions.map((user: User) => ({
            label: user.email,
            value: user.email,
          }))}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search User (name or email)"
              InputProps={{
                ...params.InputProps,
                type: 'search',
              }}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          )}
        />
        <StyledActionButton
          typeVariant="create"
          onClick={() => setOpenInviteNewUserModal(true)}
        >
          Invite New User
        </StyledActionButton>
      </TableHeaderWrapper>
      <CustomTable<User> headCells={headCells} rows={filteredOptions} />
      <InviteNewUserModal
        isOpen={openInviteNewUserModal}
        handleClose={() => setOpenInviteNewUserModal(false)}
        inviteUser={inviteNewUser}
      />
      <CustomSnackbar config={snackbarConfig} setOpen={handleSnackBarClose} />
    </>
  );
};

export default AdminUserTable;
