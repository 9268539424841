import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Tab, Tabs } from '@mui/material';
import axios from 'axios';
import { useContext, useState } from 'react';
import styled from 'styled-components';
import { Container } from '../../shared/components/Container';
import CustomSnackbar, {
  SnackBarConfig,
} from '../../shared/components/Snackbar';
import StaticTextCard from '../../shared/components/StaticTextCard';
import { UserContext } from '../../shared/contexts/UserContext';
import { UserType } from '../../shared/domain/user';
import { H2, screenMdMin } from '../../shared/styles';
import { AuthFormFieldsValues } from '../authentication/types/types.auth';
import { showErrorSnackbar } from '../authentication/utils/auth.utils';
import ChangePassword from './components/ChangePassword';
import FollowCompanyTable from './components/FollowCompanyTable';
import UpdateDetailsForm from './components/UpdateDetailsForm';
import UpdatePreferencesForm from './components/UpdatePreferencesForm';

const StyledHeader = styled(H2)`
  text-align: center;
  margin-bottom: var(--spacing-md);
  text-decoration: underline;
`;

const StyledBox = styled(Box)`
  width: 100%;
  @media ${screenMdMin} {
    width: 60%;
  }
`;

const StyledTab = styled(Tab)`
  width: 30%;
`;

const initialFormfields = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  company: '',
  positionTitle: '',
  revenue: '',
  companySize: '',
  verificationCode: '',
  industry: '',
};

const SettingsPage = () => {
  const { user } = useContext(UserContext);

  const [value, setValue] = useState('1');
  const [snackbarConfig, setSnackbarConfig] = useState<SnackBarConfig>();
  const [formFields, setFormFields] =
    useState<AuthFormFieldsValues>(initialFormfields);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleFormFieldChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const handleSnackBarClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  const updateUser = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      formFields.positionTitle &&
      formFields.positionTitle.trim().length === 0
    ) {
      setSnackbarConfig({
        type: 'error',
        message: 'Position Title cannot be blank or empty.',
        open: true,
      });
      return;
    }
    if (formFields.company && formFields.company.trim().length === 0) {
      setSnackbarConfig({
        type: 'error',
        message: 'Company name cannot be blank or empty.',
        open: true,
      });
      return;
    }
    try {
      const updateUserInput = {
        firstName: formFields.firstName || user?.firstName,
        lastName: formFields.lastName || user?.lastName,
        email: user?.email,
        company: formFields.company || user?.company,
        industry: formFields.industry || user?.industry,
        positionTitle: formFields.positionTitle || user?.positionTitle,
        companySize: formFields.companySize || user?.companySize,
        id: user?.id,
      };

      await axios.put(
        `${import.meta.env.VITE_API_BASE_URL}/user`,
        updateUserInput,
        {
          headers: {
            Authorization: user?.idToken,
          },
        }
      );
      setSnackbarConfig({
        message: 'Your account details have been successfully changed!',
        open: true,
        type: 'success',
      });
    } catch (error) {
      setSnackbarConfig(showErrorSnackbar(error.message));
    }
  };

  const unFollowSuccess = (companyName: string) => {
    setSnackbarConfig({
      message: `${companyName} successfully unfollowed.`,
      open: true,
      type: 'success',
    });
  };

  if (!user) {
    return (
      <StaticTextCard
        header="Not a Member!"
        text="It seems that you are not yet a member of Kandir, please head to our login Page."
        link={true}
      />
    );
  }

  return (
    <Container isWhite={true}>
      <StyledHeader>Account Settings</StyledHeader>
      <StyledBox>
        <TabContext value={value}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              textAlign: 'center',
            }}
          >
            <Tabs onChange={handleTabChange} centered value={value}>
              <StyledTab label="Account Details" value="1" />
              <StyledTab label="Preferences" value="2" />
              <StyledTab label="Change Password" value="3" />
              <StyledTab
                label={
                  user?.userType === UserType.COMPANY
                    ? 'Users Following'
                    : 'Followed Companies'
                }
                value="4"
              />
            </Tabs>
          </Box>
          <TabPanel value="1">
            <UpdateDetailsForm
              handleFormFieldChange={handleFormFieldChange}
              formFields={formFields}
              updateUser={updateUser}
              industryValue={formFields.industry}
            />
          </TabPanel>
          <TabPanel value="2">
            <UpdatePreferencesForm />
          </TabPanel>
          <TabPanel value="3">
            <ChangePassword />
          </TabPanel>
          <TabPanel value="4">
            {user?.Companies && user?.Companies.length === 0 ? (
              <StaticTextCard
                header="No Followed Companies"
                text="Please head to the Company Search page to find companies to follow."
                link={false}
              />
            ) : (
              <FollowCompanyTable
                data={user?.Companies || []}
                unFollowSuccess={unFollowSuccess}
              />
            )}
          </TabPanel>
        </TabContext>
      </StyledBox>
      <CustomSnackbar config={snackbarConfig} setOpen={handleSnackBarClose} />
    </Container>
  );
};

export default SettingsPage;
