import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { CustomStyledButton } from './Button';
import { Feature } from './Feature';
import { FeaturesList } from './FeaturesList';
import { PricingCard } from './PricingCard';
import { PricingQuoteModal } from './PricingQuoteModal';
import { Title } from './Title';
import { Tagline } from './Tagline';
import { PricingCardHeader } from './PricingCardHeader';
import { PricingCardData } from '../types';
import CustomSnackbar, {
  SnackBarConfig,
} from '../../../../shared/components/Snackbar';
import { H2, H4, P } from '../../../../shared/styles';
import {
  ButtonWrapper,
  FeaturesWrapper,
  StyledPricingTable,
} from '../styles/PricingTable';

const StyledHeader = styled(H2)`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: left;
  font-family: 'IBM Plex Sans', sans-serif;
`;

const StyledSubheader = styled(P)`
  text-align: center;
`;

const StyledPricingTableHeader = styled(H4)`
  text-align: center;
`;

const Container = styled.div`
  padding: var(--spacing-md);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 140px;
`;

interface PricingTableProps {
  pricingCards: PricingCardData[];
}

const PricingTable: React.FC<PricingTableProps> = ({ pricingCards }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [snackbarConfig, setSnackbarConfig] = useState<SnackBarConfig>();
  const [selectedSlug, setSelectedSlug] = useState<string>();

  const handleOpenModal = (slug: string) => {
    setSelectedSlug(slug);
    setIsModalOpen(true);
  };

  const handleSubmitEmail = (email: string, slug?: string) => {
    const input = { email, ...(slug && { slug }) };
    axios
      .post(`${import.meta.env.VITE_API_BASE_URL}/leadgen/pricing`, input)
      .then(() => {
        setSnackbarConfig({
          message:
            "Thanks for your submission. We'll be in touch with you about Kandir pricing!",
          open: true,
          type: 'success',
        });
      })
      .catch((error) => {
        setSnackbarConfig({
          message: error.message,
          open: true,
          type: 'error',
        });
      });
    setEmail('');
    setIsModalOpen(false);
  };

  return (
    <Container>
      <StyledHeader>Unlock Sales Success with Kandir</StyledHeader>
      <StyledSubheader>
        Transform your sales strategy with unparalleled insights into the buying
        process for enterprise organizations.
      </StyledSubheader>
      <StyledPricingTableHeader>
        Find the right plan for you
      </StyledPricingTableHeader>
      <StyledPricingTable>
        {pricingCards.map(({ title, tagline, buttonText, slug, features }) => (
          <PricingCard key={title}>
            <PricingCardHeader>
              <Title title={title} />
              <Tagline tagline={tagline} />
              <ButtonWrapper>
                <CustomStyledButton
                  text={buttonText}
                  onClick={() => handleOpenModal(slug)}
                />
              </ButtonWrapper>
            </PricingCardHeader>
            <FeaturesWrapper>
              <FeaturesList>
                {features.map((feature) => (
                  <Feature key={feature}>{feature}</Feature>
                ))}
              </FeaturesList>
            </FeaturesWrapper>
          </PricingCard>
        ))}
      </StyledPricingTable>
      <PricingQuoteModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleSubmitEmail}
        onEmailChange={setEmail}
        email={email}
        slug={selectedSlug}
      />
      <CustomSnackbar
        config={snackbarConfig}
        setOpen={() =>
          setSnackbarConfig((prev) =>
            prev ? { ...prev, open: false } : undefined
          )
        }
      />
    </Container>
  );
};

export default PricingTable;
