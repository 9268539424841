import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import DynamicIcon, { IconName } from '../../shared/components/Icon';

type ReviewReaderBasicQuestionProps = {
  label: string;
  answer: string;
  iconGroup?: string;
};

const getIconForLabel = (iconGroup?: string) => {
  if (!iconGroup) return IconName.Info;
  if (iconGroup) {
    switch (iconGroup) {
      case '100':
        return IconName.Money; // Financial values
      case '200':
        return IconName.AccessTime; // Duration, time
      case '300':
        return IconName.Gavel; // RFP, approval process
      case '400':
        return IconName.CalendarToday; // Dates, timelines
      case '500':
        return IconName.EventNote; // Contracts, agreements
      case '600':
        return IconName.Person; // People-related fields
      default:
        return IconName.Info;
    }
  }
};

const StyledCard = styled(Card)`
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  background: #ffffff;
  text-align: center;
  padding: 12px;
  background: #f9fbfd;
  transition: all 0.2s ease-in-out;
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  }
`;

const IconContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
`;

const LabelText = styled(Typography)`
  font-weight: bold;
  font-size: 14px;
  color: #2b90e3;
  margin-bottom: 6px;
`;

const AnswerText = styled(Typography)`
  font-weight: 500;
  font-size: 16px;
  color: #333;
`;

const ReviewReaderBasicQuestion = ({
  label,
  answer,
  iconGroup,
}: ReviewReaderBasicQuestionProps) => {
  return (
    <StyledCard>
      <CardContent>
        <IconContainer>
          <DynamicIcon
            iconName={getIconForLabel(iconGroup) || IconName.Info}
            usePrimaryColor
          />
        </IconContainer>
        <LabelText>{label}</LabelText>
        <AnswerText>{answer || 'N/A'}</AnswerText>
      </CardContent>
    </StyledCard>
  );
};

export default ReviewReaderBasicQuestion;
