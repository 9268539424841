import axios from 'axios';
import { User } from '../../scenes/authentication/types/types.auth';

class OnboardingService {
  public async markOnboardingStepComplete(
    user: User,
    stepName?: string,
    stepId?: number
  ): Promise<boolean> {
    let hasUpdates = false;
    const updatedOnboardingConfig = user?.onboardingConfig?.map((step) => {
      if (stepName && step.name === stepName) {
        hasUpdates = true;
        return { ...step, completed: true };
      } else if (stepId && step.id === stepId) {
        hasUpdates = true;
        return { ...step, completed: true };
      }
      return step;
    });

    if (!hasUpdates) {
      console.error('No onboarding step found to update');
      return false;
    }

    try {
      await axios.put(
        `${import.meta.env.VITE_API_BASE_URL}/user/${user?.id}/onboarding`,
        updatedOnboardingConfig,
        {
          headers: {
            Authorization: user?.idToken,
          },
        }
      );
      return true;
    } catch (error) {
      console.error('Error marking onboarding step complete:', error);
      return false;
    }
  }
}

export const onboardingService = new OnboardingService();
