import axios, { AxiosResponse } from 'axios';

type FilterType = 'all' | 'most_reviewed' | 'most_recent';

export type FetchCompaniesParams = {
  filter: FilterType;
  search?: number;
};

export const fetchCompanies = async (params: FetchCompaniesParams) => {
  const { filter, search } = params;
  const url = `${import.meta.env.VITE_API_BASE_URL}/company/filtered`;

  try {
    const response: AxiosResponse = await axios.post(url, {
      filter,
      search,
    });

    return response.data;
  } catch (error) {
    throw new Error('Error fetching reviews');
  }
};
