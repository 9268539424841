import React from 'react';
import { Typography, Box } from '@mui/material';
import styled from 'styled-components';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Breakpoints } from '../../../shared/styles';

type ApproversListCardProps = {
  data: string[];
  label: string;
};

const CardContainer = styled(Box)`
  background: #e9f6fd;
  border-radius: var(--border-radius);
  padding: var(--spacing-sm);
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-sm);
  position: relative;
  width: 45%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border: 2px solid #b3e0f7;

  @media (max-width: ${Breakpoints.md}) {
    width: 100%;
    margin-bottom: var(--spacing-sm);
  }
`;

const IconContainer = styled(Box)`
  background: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -12px;
  right: -12px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled(Typography)`
  font-weight: bold;
  font-size: 14px;
  color: #1e6f98;
`;

const ListItem = styled(Typography)`
  font-size: 14px;
  color: #333;
  margin-left: 16px;
  position: relative;

  &::before {
    content: '•';
    position: absolute;
    left: -12px;
    color: #1e6f98;
    font-size: 16px;
  }
`;

const ApproversListCard = (props: ApproversListCardProps) => {
  const { data, label } = props;

  return (
    <CardContainer>
      <Box>
        <Title>{label}</Title>
        <Box mt={1}>
          {data?.map((answer, index) => (
            <ListItem key={index}>{answer}</ListItem>
          ))}
        </Box>
      </Box>
      <IconContainer>
        <AssignmentIcon sx={{ color: '#1e6f98', fontSize: 22 }} />
      </IconContainer>
    </CardContainer>
  );
};

export default ApproversListCard;
