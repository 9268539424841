import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import axios from 'axios';
import { UserContext } from '../../../shared/contexts/UserContext';
import { H2, H5, P } from '../../../shared/styles';

type ReviewHeaderProps = {
  userId: number;
};

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  border-radius: 8px;
`;

export const InfoCard = styled.div`
  flex: 1 1 240px;
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardLabel = styled(H5)`
  font-size: 18px;
  color: #333;
  margin-bottom: 8px;
`;

export const CardValue = styled(P)`
  font-size: 14px;
  color: #555;
  font-weight: bold;
`;

export const SectionTitle = styled(H2)`
  color: #333;
  padding: 0;
`;

const ReviewHeader = (props: ReviewHeaderProps) => {
  const { userId } = props;
  const { user } = useContext(UserContext);
  const [reviewCount, setReviewCount] = useState<number>(0);

  const REVIEW_HEADER_MAPPINGS = [
    {
      label: 'Number of Reviews Provided',
      value: reviewCount !== null ? reviewCount : 'Loading...',
    },
    {
      label: 'Active Since',
      value: user?.createdAt
        ? dayjs(user.createdAt).format('MM/DD/YYYY')
        : 'N/A',
    },
    {
      label: 'Size of Organization of Seller',
      value: `${user?.companySize || 'N/A'} employees`,
    },
  ];

  useEffect(() => {
    if (!userId) return;
    const fetchReviewCount = async () => {
      try {
        const res = await axios.get(
          `${import.meta.env.VITE_API_BASE_URL}/review/${userId}/count`,
          {
            headers: {
              Authorization: user?.idToken,
            },
          }
        );
        setReviewCount(res.data.count);
      } catch (error) {
        setReviewCount(0);
      }
    };
    fetchReviewCount();
  }, [userId]);

  return (
    <>
      <SectionTitle>About the Reviewer</SectionTitle>
      <Wrapper>
        {REVIEW_HEADER_MAPPINGS.map((header, index) => (
          <InfoCard key={index}>
            <CardLabel>{header.label}</CardLabel>
            <CardValue>{header.value}</CardValue>
          </InfoCard>
        ))}
      </Wrapper>
    </>
  );
};

export default ReviewHeader;
