import { Fragment } from 'react';
import { Button } from '@mui/material';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import ErrorMessage from '../../../../shared/components/ErrorMessage';
import { MultipleChoiceQuestion } from '../../../../shared/domain/form';
import { screenMdMin, P } from '../../../../shared/styles';

import CustomTooltip from '../../../../shared/components/Tooltip';

type MultipleChoiceQuestionProps = {
  handleFormFieldChange: (e: any) => void;
  formFields: any;
  question: MultipleChoiceQuestion;
  errors?: any;
  isNarrow?: boolean;
  isWide?: boolean;
};

const MobileWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: var(--spacing-xs);
  @media ${screenMdMin} {
    max-width: 100%;
  }
`;

export const QuestionWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin: var(--spacing-md) 0;
  flex-direction: row;
  width: 100%;
`;

export const QuestionTextWrapper = styled.div<{ iswide?: boolean }>`
  width: ${({ iswide }) => (iswide ? '30%' : '15%')};
  min-width: 200px;
  margin-right: var(--spacing-md);
`;

export const AnswerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledAnswerButton = styled(Button)<{
  active?: boolean;
  narrow?: boolean;
}>`
  && {
    border: 1px solid var(--color-grey-dark);
    border-radius: 8px;
    color: var(--color-black);
    text-transform: capitalize;
    width: ${({ narrow }) => (narrow ? '85px' : '130px')};
    height: 55px;
    font-size: 12px;
    background-color: ${({ active }) => (active ? '#78bbf1' : 'white')};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.2s ease;

    &&.Mui-disabled {
      opacity: 1;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
`;

export const StyledQuestionText = styled(P)`
  font-size: 14px;
`;

const MultipleChoiceReviewQuestion = (props: MultipleChoiceQuestionProps) => {
  const {
    handleFormFieldChange,
    formFields,
    question,
    isNarrow,
    isWide,
    errors,
  } = props;

  return (
    <QuestionWrapper data-error-name={question.name}>
      <QuestionTextWrapper iswide={isWide}>
        <StyledQuestionText>
          {question.question}{' '}
          {question.tooltipText && (
            <CustomTooltip text={question.tooltipText} />
          )}
        </StyledQuestionText>
      </QuestionTextWrapper>
      <AnswerWrapper>
        <MobileWrapper>
          {question.options.map((value, i) => (
            <Fragment key={i}>
              <motion.div
                {...(question.conditionallyShow
                  ? {
                      initial: {
                        x: '-100%',
                        opacity: 0,
                      },
                      transition: {
                        type: 'spring',
                        stiffness: 300,
                        damping: 20,
                        delay: i * 0.1,
                      },
                    }
                  : {})}
                animate={{
                  x: 0,
                  opacity: 1,
                }}
                whileHover={{
                  scale: 1.05,
                }}
                whileTap={{ scale: 0.95 }}
              >
                <StyledAnswerButton
                  active={formFields[question.name] === value}
                  name={question.name}
                  value={value}
                  onClick={handleFormFieldChange}
                  variant="outlined"
                  narrow={isNarrow}
                >
                  {value}
                </StyledAnswerButton>
              </motion.div>
            </Fragment>
          ))}
        </MobileWrapper>
        {errors && errors[question.name] && (
          <ErrorMessage error={errors[question.name]} />
        )}
      </AnswerWrapper>
    </QuestionWrapper>
  );
};

export default MultipleChoiceReviewQuestion;
