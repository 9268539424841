import styled from 'styled-components';
import { ArrowForward, Publish } from '@mui/icons-material';
import { ButtonWrapper, StyledPublishButton } from '../styles/review.styles';
import { P } from '../../../shared/styles';

type ReviewFormButtonsProps = {
  handleContinueReview: any;
  nextButtonText: string;
  nextButtonDisabled?: boolean;
  nextButtonTestId?: string;
  altButtonText?: string;
  altButtonDisabled?: boolean;
  altButtonTestId?: string;
  handleAltButtonClick?: any;
};

const ButtonTextWrapper = styled.div`
  flex-direction: column;
  max-width: 250px;
`;

const StyledButtonText = styled(P)`
  margin-top: var(--spacing-sm);
  margin-left: var(--spacing-xs);
  font-size: 14px;
`;

const ReviewFormButtons = (props: ReviewFormButtonsProps) => {
  const {
    handleContinueReview,
    nextButtonText,
    nextButtonDisabled: disabled,
    altButtonText: altButtonText,
    altButtonDisabled: altButtonDisabled,
    handleAltButtonClick: handleAltButtonClick,
  } = props;

  return (
    <ButtonWrapper>
      {altButtonText && (
        <StyledPublishButton
          data-testid="review-step-alt-button"
          disabled={altButtonDisabled}
          variant="contained"
          onClick={handleAltButtonClick}
          endIcon={<Publish />}
        >
          {altButtonText}
        </StyledPublishButton>
      )}
      &nbsp; &nbsp;
      <ButtonTextWrapper>
        <StyledPublishButton
          data-testid="review-step-next-button"
          disabled={disabled}
          variant="contained"
          type="submit"
          onClick={handleContinueReview}
          endIcon={<ArrowForward />}
        >
          {nextButtonText}
        </StyledPublishButton>

        {nextButtonText === 'Make it a Deep Dive' && (
          <StyledButtonText>
            Deep dive reviews are the best way to empower other sales
            professionals by providing deeper intelligence into the buying
            process.
          </StyledButtonText>
        )}
      </ButtonTextWrapper>
    </ButtonWrapper>
  );
};

export default ReviewFormButtons;
