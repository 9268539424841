import React from 'react';
import ReviewTable from './ReviewTable';
import { BasicReview } from '../../../shared/domain/review';
import { ReviewFilter } from '../types/welcome.types';
import SpinnerLoader from '../../../shared/components/Loader';

interface ReviewSectionProps {
  setIsNavOpen: (isOpen: boolean) => void;
  reviews: BasicReview[] | null;
  loading: boolean;
  filterType: ReviewFilter;
}

const ReviewSection: React.FC<ReviewSectionProps> = ({
  setIsNavOpen,
  reviews,
  loading,
  filterType,
}) => {
  if (loading || (reviews && !reviews.length)) {
    return <SpinnerLoader />;
  }

  return (
    <>
      <ReviewTable
        setIsNavOpen={setIsNavOpen}
        allReviews={reviews || []}
        filterType={filterType}
        loading={loading}
      />
    </>
  );
};

export default ReviewSection;
