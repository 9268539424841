import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

type ProgressLineChartProps = {
  consumed: number;
  total: number;
  title?: string;
  subText?: string;
};

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
`;

const TitleText = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: #333;
  text-align: left;
  margin-bottom: var(--spacing-xs);
`;

const ProgressBarWrapper = styled.div`
  background-color: #e0e0e0;
  height: 12px;
  border-radius: 6px;
  overflow: hidden;
  width: 100%;
  position: relative;
`;

const ProgressBar = styled.div<{ percentage: number }>`
  background: linear-gradient(to right, #ccdcf8, #6fa4f9);
  height: 100%;
  border-radius: 6px;
  transition: width 1s ease-in-out;
  width: ${(props) => props.percentage}%;
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #555;
  margin-bottom: var(--spacing-md);
`;

const SubText = styled.div`
  text-align: right;
`;

const ProgressLineChart = ({
  consumed,
  total,
  title,
  subText,
}: ProgressLineChartProps) => {
  const [renderedPercentage, setRenderedPercentage] = useState(0);

  const percentage = Math.min(((consumed || 0) / (total || 1)) * 100, 100);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setRenderedPercentage(percentage);
    }, 0);
    return () => clearTimeout(timeoutId);
  }, [percentage]);

  return (
    <ChartWrapper>
      {title && <TitleText>{title}</TitleText>}
      <ProgressBarWrapper>
        <ProgressBar percentage={renderedPercentage} />
      </ProgressBarWrapper>
      <TextContainer data-testid="progress-bar-sub-text">
        <div />
        {subText && <SubText>{subText}</SubText>}
      </TextContainer>
    </ChartWrapper>
  );
};

export default ProgressLineChart;
