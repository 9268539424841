import React, { ReactNode } from 'react';
import { motion } from 'framer-motion';

type PhaseInAnimationProps = {
  isDataLoaded?: boolean;
  children: ReactNode;
};

const PhaseInAnimation = ({
  isDataLoaded,
  children,
}: PhaseInAnimationProps) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{
        opacity: isDataLoaded ? 1 : 0,
        y: isDataLoaded ? 0 : 20,
      }}
      transition={{ duration: 0.5 }}
      style={{ width: '100%' }}
    >
      {children}
    </motion.div>
  );
};

export default PhaseInAnimation;
