import React from 'react';
import { RatingQuestion } from '../../../../shared/domain/form';
import { CurrentReview } from '../../../../shared/domain/review';
import { Rating, Typography } from '@mui/material';
import styled from 'styled-components';
import ErrorMessage from '../../../../shared/components/ErrorMessage';
import CustomTooltip from '../../../../shared/components/Tooltip';

type ReviewRatingQuestionProps = {
  handleRatingChange: (name: string, value: number | null) => void;
  formFields: any;
  question: RatingQuestion;
  errors: Record<string, string>;
  currentReview?: CurrentReview;
  isReadOnly?: boolean;
};

const Wrapper = styled.div`
  flex-direction: column;
  width: 100%;
`;

const StyledHeader = styled.strong`
  margin: var(--spacing-sm) 0;
`;

const StyledRating = styled(Rating)`
  margin: var(--spacing-sm) 0;
`;

const ReviewRatingQuestion = (props: ReviewRatingQuestionProps) => {
  const {
    handleRatingChange,
    formFields,
    currentReview,
    question,
    errors,
    isReadOnly,
  } = props;

  const onRatingChange = (
    event: React.SyntheticEvent,
    newValue: number | null
  ) => {
    handleRatingChange(question.name, newValue);
  };

  const value =
    formFields[question.name] ||
    currentReview?.ratings?.find(
      (responseRating) => responseRating?.type === question.responseName
    )?.rating ||
    0;

  return (
    <>
      <Wrapper>
        <Typography component="legend">
          <StyledHeader>
            {question.question}{' '}
            {question.tooltipText && (
              <CustomTooltip text={question.tooltipText} />
            )}
          </StyledHeader>
        </Typography>
        <StyledRating
          data-testid={`rating-${question.name}-input`}
          onChange={onRatingChange}
          name={question.name}
          size="large"
          value={value}
          readOnly={isReadOnly}
        />
        {errors && !value && errors[question.name] && (
          <ErrorMessage
            key={`${formFields[question.name]}-error`}
            error={errors[question.name]}
          />
        )}
      </Wrapper>
    </>
  );
};

export default ReviewRatingQuestion;
