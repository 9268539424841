import React, { SyntheticEvent, useState } from 'react';
import {
  Typography,
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import Button from '../../../shared/components/Buttons/Button';
import { Breakpoints } from '../../../shared/styles';

import stockImage from '../../../assets/images/stock.jpeg';

type BannerProps = {
  companyName: string;
  reviewDate: string;
  sections: { key: string; title: string }[];
  onScrollToSection: (id: string) => void;
};

const fadeIn = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: 'easeOut' } },
};

const CompanyProfileButtonWrapper = styled.div`
  margin-top: auto;
`;

const BannerWrapper = styled(motion.div)`
  background: #f8f9fa;
  position: sticky;
  border-radius: 12px;
  padding: var(--spacing-md);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  margin: var(--spacing-md);
  margin-bottom: 0;

  @media (max-width: ${Breakpoints.md}) {
    flex-wrap: wrap;
    padding: var(--spacing-sm);
  }
`;

const Logo = styled.img`
  height: 100px;
  width: 100px;
  object-fit: contain;
  border-radius: 8px;
  background: #ffffff;
  padding: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  @media (max-width: ${Breakpoints.md}) {
    height: 50px;
    width: 50px;
    margin-bottom: 8px;
  }
`;

const ContentContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-grow: 1;

  @media (max-width: ${Breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
`;

const CompanyInfo = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const CompanyName = styled(Typography)`
  font-size: 20px;
  font-weight: bold;
  color: #2b90e3;
  cursor: pointer;
`;

const ReviewDate = styled(Typography)`
  font-size: 14px;
  color: #555;
  margin-top: 4px;
`;

const SectionNav = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: var(--spacing-sm);

  @media (max-width: ${Breakpoints.md}) {
    flex-direction: column;
    width: 100%;
  }
`;

const SectionButton = styled(Button)`
  font-size: 12px;
  padding: 4px 10px;
  min-width: auto;
  white-space: nowrap;
`;

const MobileDropdownContainer = styled(Box)`
  display: none;

  @media (max-width: ${Breakpoints.md}) {
    display: block;
    width: 100%;
  }
`;

const ReviewBanner = ({
  companyName,
  reviewDate,
  sections,
  onScrollToSection,
}: BannerProps) => {
  const navigate = useNavigate();
  const [selectedSection, setSelectedSection] = useState('');

  const defaultImage = (ev: SyntheticEvent<HTMLImageElement, Event>) => {
    (ev.target as HTMLImageElement).src = stockImage;
  };

  const navToCompanyProfile = () => navigate(`/company-search/${companyName}`);

  const handleSectionChange = (event: SelectChangeEvent<string>) => {
    const selectedId = event.target.value;
    setSelectedSection(selectedId);
    onScrollToSection(selectedId);
  };

  return (
    <BannerWrapper initial="hidden" animate="visible" variants={fadeIn}>
      <ContentContainer>
        <Logo
          src={`https://kandir-images.s3.eu-west-2.amazonaws.com/Company Logos/${companyName}.jpeg`}
          alt={companyName}
          onClick={navToCompanyProfile}
          onError={defaultImage}
        />
        <CompanyInfo>
          <CompanyName onClick={navToCompanyProfile}>{companyName}</CompanyName>
          <ReviewDate>
            <strong>Review Created:</strong> {reviewDate}
          </ReviewDate>

          <SectionNav>
            <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 1 }}>
              {sections.map(({ key, title }) => (
                <SectionButton
                  key={key}
                  size="small"
                  onClick={() => onScrollToSection(key)}
                >
                  {title}
                </SectionButton>
              ))}
            </Box>

            <MobileDropdownContainer>
              <Select
                value={selectedSection}
                onChange={handleSectionChange}
                displayEmpty
                fullWidth
              >
                <MenuItem value="" disabled>
                  Jump to Section
                </MenuItem>
                {sections.map(({ key, title }) => (
                  <MenuItem key={key} value={key}>
                    {title}
                  </MenuItem>
                ))}
              </Select>
            </MobileDropdownContainer>
          </SectionNav>
        </CompanyInfo>
      </ContentContainer>
      <CompanyProfileButtonWrapper>
        <Button size="small" onClick={navToCompanyProfile}>
          Company Profile
        </Button>
      </CompanyProfileButtonWrapper>
    </BannerWrapper>
  );
};

export default ReviewBanner;
