import Divider from '@mui/material/Divider';
import { useContext } from 'react';
import styled from 'styled-components';
import StaticTextCard from '../../../shared/components/StaticTextCard';
import { UserContext } from '../../../shared/contexts/UserContext';
import { BasicReview } from '../../../shared/domain/review';
import { H2 } from '../../../shared/styles';
import CompanyReview from './CompanyReview';
import { useReviewContext } from '../../review/context/ReviewContext';
import Loader from '../../../shared/components/Loader';
import { isEmpty } from 'lodash';

type CompanyProfileReviewsProps = {
  reviews: Array<BasicReview>;
  title: string;
  handleOpenReviewDialog: (reviewId?: number) => void;
  handleDeepDiveClick?: (review: BasicReview) => void;
};

const StyledHeader = styled(H2)`
  text-decoration: underline;
  margin: var(--spacing-md) 0;
`;

const Wrapper = styled.div`
  display: flex;
  margin-left: var(--spacing-lg);
  justify-content: space-between;
  width: 100%;
`;

const CardWrapper = styled.div`
  flex-direction: column;
  width: 100%;
`;

const CompanyProfileReviews = (props: CompanyProfileReviewsProps) => {
  const { reviews, title, handleOpenReviewDialog, handleDeepDiveClick } = props;
  const { user, userLoaded } = useContext(UserContext);
  const { loading } = useReviewContext();

  // Case 1: Loading is still in progress.
  // Show spinner
  if (loading || !userLoaded) {
    return (
      <Wrapper>
        <CardWrapper>
          <StyledHeader>{title}</StyledHeader>
          <Divider sx={{ width: '100%' }} />
          <Loader />
        </CardWrapper>
      </Wrapper>
    );
  }

  // Case 2: User is not loaded
  if (isEmpty(user)) {
    return (
      <Wrapper>
        <CardWrapper>
          <StyledHeader>{title}</StyledHeader>
          <Divider />
          &nbsp;
          <StaticTextCard
            header="Must be Logged In"
            text="Please either log in or sign up to see reviews. "
            link={true}
            isCompanyProfile={false}
          />
        </CardWrapper>
      </Wrapper>
    );
  }

  // Case 3: User is loaded and reviews exist.
  // Show reviews
  if (!isEmpty(user) && reviews.length > 0) {
    return (
      <Wrapper>
        <CardWrapper>
          <StyledHeader>{title}</StyledHeader>
          <Divider />
          &nbsp;
          {reviews.map((review: BasicReview) => (
            <CompanyReview
              review={review}
              key={review.id}
              handleOpenReviewDialog={handleOpenReviewDialog}
              handleDeepDiveClick={handleDeepDiveClick}
              userId={review.userId || 0}
            />
          ))}
        </CardWrapper>
      </Wrapper>
    );
  }

  // Case 4: User is loaded but no reviews exist.
  return (
    <Wrapper>
      <CardWrapper>
        <StyledHeader>{title}</StyledHeader>
        <Divider />
        &nbsp;
        <StaticTextCard
          header="No Reviews Exist for this Company!"
          text="Please either leave a review, or come back at a later date."
          link={false}
          actionClick={() => handleOpenReviewDialog()}
          isCompanyProfile={true}
        />
      </CardWrapper>
    </Wrapper>
  );
};

export default CompanyProfileReviews;
