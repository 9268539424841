import '../terms-of-use/TermsOfUse.css'; // TODO fix
import PreLoginContainer from '../components/PreLoginContainer';

const PrivacyPolicy = () => {
  return (
    <PreLoginContainer>
      <div className="c17 doc-content">
        <h1 className="c13" id="h.wyd7toxpk1ld">
          <span className="c21">Privacy Policy</span>
        </h1>

        <p className="c8">
          <span className="c1">
            Kandir, Inc. (referred to as &quot;we,&quot; &quot;us,&quot; or
            &quot;our&quot;) is committed to protecting your privacy and
            ensuring the security of your personal information. This Privacy
            Policy outlines how we collect, use, disclose, and safeguard your
            personal data. By using our services, you consent to the practices
            described in this policy.
          </span>
        </p>
        <h2 className="c6" id="h.aowh6pvlzy22">
          <span className="c9">1. Information We Collect</span>
        </h2>

        <p className="c8">
          <span className="c1">
            We may collect the following types of information:
          </span>
        </p>

        <ul className="c5 lst-kix_le6adhs8ysc7-0 start">
          <li className="c2 li-bullet-0">
            <span className="c1">
              Personal Information: This may include your name, email address,
              mailing address, phone number, and other contact details.
            </span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c1">
              Payment Information: If you make a purchase, we collect payment
              details, such as credit card information or other payment methods.
            </span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c1">
              Usage Information: We collect data about your interactions with
              our website, including your IP address, browser type, and pages
              visited.
            </span>
          </li>
        </ul>
        <p className="c6">
          <span className="c9">2. How We Use Your Information</span>
        </p>

        <p className="c8">
          <span className="c1">
            We use the collected information for the following purposes:
          </span>
        </p>
        <ul className="c5 lst-kix_le6adhs8ysc7-0 start">
          <li className="c2 li-bullet-0">
            <span className="c1">To provide and maintain our services.</span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c1">
              To process transactions and send transaction notifications.
            </span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c1">To improve our website and services.</span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c1">
              To send promotional emails and updates (you can opt out at any
              time).
            </span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c1">
              To respond to your inquiries and support requests.
            </span>
          </li>
        </ul>

        <p className="c6">
          <span className="c9">3. Data Sharing</span>
        </p>
        <p className="c8">
          <span className="c1">
            We may share your personal information with:
          </span>
        </p>

        <ul className="c5 lst-kix_le6adhs8ysc7-0 start">
          <li className="c2 li-bullet-0">
            <span className="c1">
              Service providers who help us deliver our services.
            </span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c1">
              Law enforcement or government authorities when required by law.
            </span>
          </li>
        </ul>

        <p className="c6">
          <span className="c9">4. Cookies and Tracking Technologies</span>
        </p>

        <p className="c8">
          <span className="c1">
            We use cookies and similar tracking technologies to enhance your
            experience on our website. You can manage your cookie preferences in
            your browser settings.
          </span>
        </p>

        <p className="c6">
          <span className="c9">5. Security</span>
        </p>

        <p className="c8">
          <span className="c1">
            We implement appropriate security measures to protect your personal
            information. However, no data transmission over the internet is 100%
            secure.
          </span>
        </p>

        <p className="c6">
          <span className="c9">6. Your Rights</span>
        </p>

        <p className="c8">
          <span className="c1">
            You have the right to access, correct, or delete your personal
            information. Please contact us to exercise these rights.
          </span>
        </p>

        <p className="c6">
          <span className="c9">7. Changes to this Policy</span>
        </p>

        <p className="c8">
          <span className="c1">
            We may update this Privacy Policy to reflect changes to our
            practices. We will notify you of any significant updates.
          </span>
        </p>

        <p className="c6">
          <span className="c9">8. Contact Us</span>
        </p>

        <p className="c8">
          <span className="c1">
            If you have questions or concerns about our Privacy Policy, please
            contact us at support@kandir.io
          </span>
        </p>
      </div>
    </PreLoginContainer>
  );
};

export default PrivacyPolicy;
