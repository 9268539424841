import React, { useState } from 'react';
import { TabContext, TabPanel } from '@mui/lab';
import { Box, Tabs, Tab } from '@mui/material';
import styled from 'styled-components';
import AdminUserTable from './AdminUserTable';
import CompanyRequestsTable from './CompanyRequestsTable';

const TableContainer = styled.div`
  width: 100%;
  align-items: center;
  margin: auto;
`;

const StyledTab = styled(({ active, ...other }) => <Tab {...other} />)`
  && {
    border-radius: var(--spacing-xs);
    color: ${(props) => (props.active ? '#ffffff' : '#000000')};
    background-color: ${(props) => (props.active ? '#ADD8E6' : 'transparent')};
    &:hover {
      background-color: ${(props) => (props.active ? '#ADD8E6' : '#f0f0f0')};
    }
  }
`;

const AdminTabs = () => {
  const [tabValue, setTabValue] = useState('1');

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <TableContainer>
      <TabContext value={tabValue}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            textAlign: 'center',
          }}
        >
          <Tabs value={tabValue} onChange={handleTabChange} centered>
            <StyledTab label="Users" value="1" active={tabValue === '1'} />
            <StyledTab
              label="Company Requests"
              value="2"
              active={tabValue === '2'}
            />
          </Tabs>
        </Box>

        <TabPanel value="1">
          <TableContainer>
            <AdminUserTable tabChange={tabValue} />
          </TableContainer>
        </TabPanel>
        <TabPanel value="2">
          <TableContainer>
            <CompanyRequestsTable tabChange={tabValue} />
          </TableContainer>
        </TabPanel>
      </TabContext>
    </TableContainer>
  );
};

export default AdminTabs;
