import { Grid, Box, Typography } from '@mui/material';
import ReviewRating from '../../review/components/ReviewRating';
import styled from 'styled-components';

const RatingsHeader = styled(Typography)`
  font-size: 20px;
  font-weight: bold;
  color: #2b90e3;
  padding-bottom: 12px;
  border-bottom: 2px solid #e0e0e0;
  margin: var(--spacing-md) 0;
`;

const RatingItem = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  background: #f8f9fa;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 12px;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;

  &:hover {
    background: #eef2f7;
    transform: scale(1.02);
  }

  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 12px;
  }
`;

const Label = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  color: #333;
  flex: 1;
  white-space: nowrap;
  text-align: left;
  margin-right: var(--spacing-sm);

  @media (max-width: 768px) {
    font-size: 15px;
  }
`;

const RatingWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`;

const RatingsReader: React.FC<{
  ratings: { label: string; answer: string | number | null }[];
}> = ({ ratings }) => {
  const labelMap: { [key: string]: string } = {
    transparency: 'Transparency',
    clarity: 'Clarity of Needs',
    understanding: 'Technical Competence',
    responsiveness: 'Responsiveness',
  };

  return (
    <>
      <RatingsHeader>Review Ratings</RatingsHeader>
      <Grid container spacing={2}>
        {ratings.map((rating, index) => (
          <Grid item xs={12} md={6} key={index}>
            <RatingItem>
              <Label>{labelMap[rating.label.toLowerCase()]}</Label>
              <RatingWrapper>
                <ReviewRating
                  value={String(rating.answer)}
                  isReadOnly={true}
                  label={''}
                  name={''}
                />
              </RatingWrapper>
            </RatingItem>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default RatingsReader;
