import React from 'react';
import { Button } from '@mui/material';
import styled from 'styled-components';
import DynamicIcon, { IconName } from '../../../shared/components/Icon';

type DeepDiveReviewButtonProps = {
  onClick: () => void;
  text: string;
  iconName?: IconName | null;
};

const StyledDeepDiveButton = styled(Button)`
  text-transform: capitalize;
  text-align: center;
  position: relative;
  border-radius: var(--spacing-md);
  width: 300px;
  padding: var(--spacing-md);
  font-size: 18px;
  color: var(--color-black);
  background-color: var(--color-primary-light-blue);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 120px;
  margin: 0 auto;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: var(--color-primary-light-blue);
    box-shadow: 0 12px 20px rgba(0, 123, 255, 0.4);
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 6px 10px rgba(0, 123, 255, 0.2);
  }
`;

const ReviewButton = (props: DeepDiveReviewButtonProps) => {
  const { onClick, text, iconName } = props;

  return (
    <StyledDeepDiveButton onClick={onClick}>
      {iconName && <DynamicIcon iconName={iconName} size="large" />}
      {text}
    </StyledDeepDiveButton>
  );
};

export default ReviewButton;
