import React, { useState } from 'react';
import { Button } from '@mui/material';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import { motion, AnimatePresence } from 'framer-motion';

const UserNotice = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
  padding: 15px;
  border: dashed 1px #ddd;
  border-left: solid 2px blue;
  font-size: 13px;
  line-height: 20px;
  background: white;
  width: 100%;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-grow: 1;
`;

const StyledNoticeButton = styled(Button)`
  white-space: nowrap;
  width: 95px;
  font-size: 12px;
  height: 30px;
  margin-left: var(--spacing-sm);
  text-transform: none;
`;

const StyledIcon = styled(TipsAndUpdatesOutlinedIcon)`
  font-size: 20px;
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;

type BannerProps = {
  onClick: () => void;
  title: string;
  description: string;
  buttonText: string;
  dismissible?: boolean;
};

const Banner = (props: BannerProps) => {
  const { onClick, title, description, buttonText, dismissible = true } = props;
  const [isVisible, setIsVisible] = useState(true);

  return (
    <AnimatePresence>
      {isVisible && (
        <UserNotice
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.5 }}
        >
          <TextContainer>
            <StyledIcon />
            <strong>{title}</strong> {description}
            {buttonText && (
              <StyledNoticeButton
                variant="contained"
                size="small"
                onClick={onClick}
              >
                {buttonText}
              </StyledNoticeButton>
            )}
          </TextContainer>
          {dismissible && (
            <StyledCloseIcon onClick={() => setIsVisible(false)} />
          )}
        </UserNotice>
      )}
    </AnimatePresence>
  );
};

export default Banner;
