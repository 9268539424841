import React, { useEffect } from 'react';
import {
  PurchaseDetailsReview,
  ReviewStep,
} from '../../../../shared/domain/review';
import { useExtendState } from '../../../../shared/utils/ExtendState';
import { PURCHASE_DETAILS_FORM_FIELDS } from '../../data';
import { Container } from '../../styles/review.styles';
import ReviewFormButtons from '../ReviewFormButtons';
import { useReviewContext } from '../../context/ReviewContext';
import useValidation from '../../hooks/useValidation';
import MultipleChoiceReviewQuestion from '../question_types/ReviewMultipleChoiceQuestion';
import {
  MultipleChoiceQuestion,
  QuestionType,
  ShortTextQuestion,
} from '../../../../shared/domain/form';
import ReviewShortTextQuestion from '../question_types/ReviewShortTextQuestion';

type PurchaseDetailsReviewProps = {
  submitReviewForm: (
    e: React.FormEvent<HTMLFormElement>,
    formFields: PurchaseDetailsReview,
    reviewStep: ReviewStep,
    isDraft?: boolean
  ) => void;
};

const PurchaseDetailsReviewForm = (props: PurchaseDetailsReviewProps) => {
  const { submitReviewForm } = props;

  const { currentReview } = useReviewContext();

  const [formFields, extendFormFields, setFormFields] =
    useExtendState<PurchaseDetailsReview>(
      currentReview ?? ({} as PurchaseDetailsReview)
    );

  const { errors, validateFields, validateField } = useValidation(
    formFields,
    PURCHASE_DETAILS_FORM_FIELDS
  );
  const submitPurchaseDetailsReview = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const hasErrors = validateFields();
    if (hasErrors) {
      return;
    }

    submitReviewForm(e, formFields, ReviewStep.PURCHASE_DETAILS, true);
  };

  useEffect(() => {
    setFormFields(currentReview ?? ({} as PurchaseDetailsReview));
  }, [currentReview]);

  return (
    <Container>
      <form>
        {PURCHASE_DETAILS_FORM_FIELDS.filter(
          (s): s is ShortTextQuestion => s.type === QuestionType.SHORT_TEXT
        ).map((question) => (
          <ReviewShortTextQuestion
            key={question.name}
            errors={errors}
            question={question}
            formFields={formFields}
            data-error-name={question.name}
            handleFormFieldChange={(e) => {
              extendFormFields({ [e.target.name]: e.target.value });
              validateField(question.name, e.currentTarget.value);
            }}
          />
        ))}
        {PURCHASE_DETAILS_FORM_FIELDS.filter(
          (q): q is MultipleChoiceQuestion =>
            q.type === QuestionType.MULTIPLE_CHOICE
        ).map((question) => (
          <MultipleChoiceReviewQuestion
            key={question.name}
            data-error-name={question.name}
            errors={errors}
            question={question}
            formFields={formFields}
            handleFormFieldChange={(e) => {
              extendFormFields({ [question.name]: e.currentTarget.value });
              validateField(question.name, e.currentTarget.value);
            }}
            isNarrow={question.options.length > 8}
          />
        ))}

        <ReviewFormButtons
          handleContinueReview={(e: React.FormEvent<HTMLFormElement>) =>
            submitPurchaseDetailsReview(e)
          }
          nextButtonText="Move onto Evaluation"
        />
      </form>
    </Container>
  );
};

export default PurchaseDetailsReviewForm;
