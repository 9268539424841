import React, { useContext, useEffect, useState } from 'react';
import CustomTable from '../../../shared/components/Tables/Table';
import { Company } from '../../authentication/types/types.auth';
import axios from 'axios';
import LoadingProgress from '../../../shared/components/LoadingProgress';
import CustomSnackbar, {
  SnackBarConfig,
} from '../../../shared/components/Snackbar';
import { UserContext } from '../../../shared/contexts/UserContext';
import dayjs from 'dayjs';
import { AdminActionsEnum } from '../types/admin.types';
import { HeadCell } from '../../../shared/components/Tables/TableHeader';
import { TextField } from '@mui/material';
import { TableHeaderWrapper, StyledAutocomplete } from '../styles/admin.styles';
import ApproveCompanyModal from './ApproveCompanyModal';
import MigrateReviewsModal from './MigrateReviewsModal';

type AdminWaitlistTableProps = {
  tabChange: string;
};

const extractTableData = (company: Company) => {
  const { companyName, stockTicker, id, reviews, createdAt } = company;
  return {
    companyName,
    stockTicker,
    id,
    reviewId: reviews?.[0]?.id,
    createdAt: dayjs(createdAt).format('MM/DD/YYYY'),
    actions: [
      { action: AdminActionsEnum.APPROVE_COMPANY, typeVariant: 'create' },
      {
        action: AdminActionsEnum.MIGRATE_COMPANY_REVIEWS,
        typeVariant: 'create',
      },
    ],
  };
};

const headCells: HeadCell[] = [
  {
    id: 'companyName',
    showSort: true,
    disablePadding: true,
    label: 'Company Name',
  },
  {
    id: 'stockTicker',
    showSort: true,
    disablePadding: false,
    label: 'Stock Ticker',
  },
  {
    id: 'companyId',
    showSort: true,
    disablePadding: false,
    label: 'Company ID',
  },
  {
    id: 'reviewId',
    showSort: true,
    disablePadding: false,
    label: 'Review ID',
  },
  {
    id: 'createdAt',
    showSort: true,
    disablePadding: false,
    label: 'Created',
  },
  {
    id: 'Actions',
    showSort: false,
    disablePadding: false,
    label: 'Actions',
  },
];

const CompanyRequestsTable = (props: AdminWaitlistTableProps) => {
  const { tabChange } = props;
  const [loading, setLoading] = useState(false);
  const [approveCompanyModal, setApproveCompanyModal] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState<SnackBarConfig>();
  const [openMigrateReviewModal, setOpenMigrateReviewModal] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyId, setCompanyId] = useState(0);
  const [tableData, setTableData] = useState([]);

  const { user } = useContext(UserContext);

  const handleSnackBarClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  const getCompanyRequests = async () => {
    if (!user?.idToken) return;
    setLoading(true);
    try {
      const res = await axios.get(
        `${import.meta.env.VITE_API_BASE_URL}/admin/company/request`,
        {
          headers: {
            Authorization: user?.idToken,
          },
        }
      );
      const tableData = res.data
        .filter((company: Company) => company.reviews && company.reviews.length)
        .map((company: Company) => extractTableData(company));
      setTableData(tableData);
    } catch (error) {
      setSnackbarConfig({
        message: 'An error has occurred, please try again later',
        type: 'error',
        open: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const approveCompanyRecord = async (
    e: React.FormEvent<HTMLFormElement>,
    companyValues: Company
  ) => {
    e.preventDefault();
    if (!user?.idToken) return;
    setLoading(true);
    try {
      const payload = {
        company: { ...companyValues, id: companyId, isApproved: true },
        userId: user?.id,
        email: user?.email,
      };

      await axios.put(
        `${import.meta.env.VITE_API_BASE_URL}/admin/company/${companyId}`,
        payload,
        {
          headers: {
            Authorization: user.idToken,
          },
        }
      );
      setApproveCompanyModal(false);
      await getCompanyRequests();
      setSnackbarConfig({
        message: `${companyName} has been approved successfully!`,
        type: 'success',
        open: true,
      });
    } catch (error) {
      setSnackbarConfig({
        message: 'An error has occurred, please try again later',
        type: 'error',
        open: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const filteredOptions = tableData.filter(
    (company: Company) =>
      (company.companyName &&
        company.companyName
          .toLowerCase()
          .includes(searchInput.toLowerCase())) ||
      (company.id &&
        company.id.toString().toLowerCase().includes(searchInput.toLowerCase()))
  );

  useEffect(() => {
    setLoading(true);
    getCompanyRequests();
    setLoading(false);
  }, [user?.idToken, tabChange, openMigrateReviewModal]);

  const openCompanyApprovalModal = (companyName: string, companyId: number) => {
    setApproveCompanyModal(true);
    setCompanyName(companyName);
    setCompanyId(companyId);
  };

  const openMigrateReviewsModal = () => {
    setOpenMigrateReviewModal(true);
  };

  if (loading) {
    return <LoadingProgress />;
  }

  return (
    <>
      <TableHeaderWrapper>
        <StyledAutocomplete
          freeSolo
          disableClearable
          options={tableData.map((company: any) => ({
            label: company.companyName,
            value: company.companyName,
          }))}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search by Company name or ID"
              InputProps={{
                ...params.InputProps,
                type: 'search',
              }}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          )}
        />
      </TableHeaderWrapper>
      <CustomTable
        headCells={headCells}
        rows={filteredOptions}
        tabChange={tabChange}
        openCompanyApprovalModal={openCompanyApprovalModal}
        openMigrateReviewModal={openMigrateReviewsModal}
      />
      <ApproveCompanyModal
        isOpen={approveCompanyModal}
        setIsOpen={() => setApproveCompanyModal(false)}
        companyName={companyName}
        approveCompanyRecord={approveCompanyRecord}
      />
      <MigrateReviewsModal
        isOpen={openMigrateReviewModal}
        setIsOpen={() => setOpenMigrateReviewModal(false)}
        reviewData={tableData}
      />
      <CustomSnackbar config={snackbarConfig} setOpen={handleSnackBarClose} />
    </>
  );
};

export default CompanyRequestsTable;
