import React, {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  MouseEvent,
} from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { Rating as RatingUI } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import LoadingProgress from '../../../shared/components/LoadingProgress';
import { UserContext } from '../../../shared/contexts/UserContext';
import {
  BasicReview,
  RatingAspect,
  RatingSummary,
  ReviewDepth,
} from '../../../shared/domain/review';
import { P } from '../../../shared/styles';
import { ReviewFilter } from '../types/welcome.types';
import { useResponsive } from '../../../shared/hooks/useResponsive';
import { motion } from 'framer-motion';
import ContextMenu from '../../../shared/components/ContextMenu';
import Button from '../../../shared/components/Buttons/Button';
import ReviewUnlockModal from '../../review/components/DeepDiveReviewCountModal';
import { useReviewContext } from '../../review/context/ReviewContext';
import DynamicIcon, { IconName } from '../../../shared/components/Icon';
import stockImageLink from '../../../assets/images/stock.jpeg';

type ReviewTableProps = {
  allReviews: BasicReview[];
  setIsNavOpen: (isOpen: boolean) => void;
  filterType: ReviewFilter;
  loading: boolean;
};

type ContextMenuState = {
  mouseX: number;
  mouseY: number;
  review: BasicReview | null;
} | null;

const CompanyImage = styled.img`
  border-radius: var(--border-radius);
  cursor: pointer;
  height: 100px;
  width: 100px;
`;

const MotionTableRow = motion(styled(TableRow)`
  border-radius: var(--spacing-sm);
  border-bottom: var(--spacing-lg) solid var(--color-grey-light);
  background-color: var(--color-white);
`);

const rowVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { type: 'spring', stiffness: 50 } },
};

const RatingWrapper = styled.div`
  display: flex;
  margin-bottom: var(--spacing-sm);
`;

const StyledFollowText = styled(P)`
  font-size: 14px;
  margin-top: var(--spacing-sm);
`;

const StyledRatingText = styled(P)`
  width: 60%;
  font-size: 14px;
  text-align: left;
`;

const StyledCompanyName = styled(P)`
  font-size: 14px;
  text-align: center;
  width: 100px;
  font-weight: bold;
`;

const RATING_MAPPINGS = [
  { name: RatingAspect.TRANSPARENCY, label: 'Transparency:' },
  { name: RatingAspect.CLARITY, label: 'Clarity:' },
  { name: RatingAspect.UNDERSTANDING, label: 'Technical Competence:' },
  { name: RatingAspect.RESPONSIVENESS, label: 'Responsiveness:' },
];

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  showSort: boolean;
  align?: string;
}

type Alignment = 'center' | 'inherit' | 'left' | 'right' | 'justify';

const reviewHeadCells: HeadCell[] = [
  {
    id: 'isFollowed',
    showSort: false,
    disablePadding: false,
    label: 'Type',
  },
  {
    id: 'reviewDepth',
    showSort: true,
    disablePadding: false,
    label: 'Depth',
  },
  {
    id: 'companyName',
    showSort: true,
    disablePadding: false,
    label: 'Company Name',
  },
  {
    id: 'Ratings',
    showSort: false,
    disablePadding: false,
    label: 'Ratings',
  },
  {
    id: 'createdAt',
    showSort: true,
    disablePadding: false,
    label: 'Date Created',
  },
  {
    id: 'actions',
    showSort: false,
    disablePadding: false,
    label: 'Actions',
  },
];

type EnhancedTableProps = {
  order: boolean;
  orderBy: string;
  setOrder: (isAsc: boolean) => void;
  setOrderBy: (property: string) => void;
  headCells: Array<HeadCell>;
  align?: Alignment | undefined;
  filterType?: ReviewFilter;
};

export const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { order, orderBy, setOrderBy, setOrder, headCells } = props;

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order;
    setOrder(isAsc ? false : true);
    setOrderBy(property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells?.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={(headCell.align as Alignment) || 'left'}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              disabled={!headCell.showSort}
              direction={
                orderBy === headCell.id ? (order ? 'asc' : 'desc') : 'asc'
              }
              onClick={() => handleRequestSort(headCell.id)}
            >
              <strong>{headCell.label}</strong>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

type MobileReviewTableProps = {
  sortedRows: BasicReview[];
  defaultImage: (ev: React.SyntheticEvent<HTMLImageElement, Event>) => void;
  navToCompany: (companyName: string) => void;
};

const MobileReviewTable = (props: MobileReviewTableProps) => {
  const { sortedRows, defaultImage, navToCompany } = props;
  return (
    <TableBody>
      {sortedRows.map((review: BasicReview) => (
        <MotionTableRow key={review.id}>
          <TableCell align="left">
            <CompanyImage
              onClick={() =>
                navToCompany(review?.companyName?.toString() || '')
              }
              src={`https://kandir-images.s3.eu-west-2.amazonaws.com/Company Logos/${review.companyName || 'default'}.jpeg`}
              onError={defaultImage}
              alt={review.companyName || 'Company Logo'}
            />
            <StyledCompanyName>{review.companyName}</StyledCompanyName>
          </TableCell>
          <TableCell>
            <StyledFollowText bold>
              {review.reviewDepth === ReviewDepth.BASIC
                ? 'Basic'.toLocaleUpperCase()
                : 'Deep-Dive'.toLocaleUpperCase()}
            </StyledFollowText>
          </TableCell>
        </MotionTableRow>
      ))}
    </TableBody>
  );
};

const ReviewTable = (props: ReviewTableProps) => {
  const { allReviews, setIsNavOpen, filterType, loading } = props;

  const { user } = useContext(UserContext);
  const { isMobile } = useResponsive();
  const navigate = useNavigate();
  const tableRef = useRef<HTMLDivElement>(null);

  const [order, setOrder] = useState<boolean>(false);
  const [orderBy, setOrderBy] = useState('createdAt');
  const [navToCompanyName, setNavToCompanyName] = useState<string>('');
  const [navToReviewId, setNavToReviewId] = useState<number>();

  const [contextMenu, setContextMenu] = useState<ContextMenuState>(null);
  const [deepDiveReviewLimitModal, setDeepDiveReviewLimitModal] =
    useState(false);

  const handleContextMenu = (
    event: MouseEvent<HTMLTableCellElement>,
    review: BasicReview
  ) => {
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      review,
    });
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleOpenProfile = (review: BasicReview) => {
    if (review?.companyName) {
      window.open(`/company-search/${review.companyName}`, '_blank');
    }
    handleClose();
  };

  const navToCompany = (companyName: string) =>
    navigate(`/company-search/${companyName}`);

  const handleReviewClick = (review: BasicReview) => {
    if (review) {
      setNavToReviewId(review.id);
      if (review.companyName) {
        setNavToCompanyName(review.companyName);
        if (review.viewed) {
          navigate(`/review-reader/${review.companyName}/${String(review.id)}`);
          return;
        }
        if (
          user?.planDeepDiveReviewLimit &&
          review.reviewDepth === ReviewDepth.DEEP_DIVE &&
          user?.id !== review.userId
        ) {
          setDeepDiveReviewLimitModal(true);
          return;
        } else {
          navigate({
            pathname: `/review-reader/${review.companyName}/${String(review.id)}`,
            search: `?${createSearchParams({
              reviewId: String(review.id),
            })}`,
          });
        }
      }
    }
  };

  const handleRequestSort = () => {
    setOrder(!order);
  };

  const defaultImage = (ev: React.SyntheticEvent<HTMLImageElement, Event>) => {
    return ((ev.target as HTMLImageElement).src = stockImageLink);
  };

  const sortedRows = useMemo(() => {
    if (!allReviews || allReviews.length === 0) return [];

    const comparator = (a: BasicReview, b: BasicReview) => {
      if (orderBy === 'createdAt') {
        const dateA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
        const dateB = b.createdAt ? new Date(b.createdAt).getTime() : 0;
        return order ? dateA - dateB : dateB - dateA;
      }

      const valueA = a[orderBy as keyof BasicReview];
      const valueB = b[orderBy as keyof BasicReview];

      if (valueA === valueB) return 0;
      if (valueA === undefined) return order ? -1 : 1;
      if (valueB === undefined) return order ? 1 : -1;

      return order ? (valueA < valueB ? -1 : 1) : valueA > valueB ? -1 : 1;
    };

    return [...allReviews].sort(comparator);
  }, [allReviews, order, orderBy]);

  useEffect(() => {
    setIsNavOpen(false);
  }, []);

  if (loading) {
    return <LoadingProgress />;
  }

  return (
    <>
      <TableContainer ref={tableRef} data-testid="company-search-results-table">
        <Table>
          {isMobile ? (
            <MobileReviewTable
              sortedRows={sortedRows}
              navToCompany={navToCompany}
              defaultImage={defaultImage}
            />
          ) : (
            <>
              <EnhancedTableHead
                order={order}
                headCells={reviewHeadCells}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                setOrder={handleRequestSort}
                filterType={filterType}
              />
              <TableBody>
                {sortedRows?.map((review: BasicReview, index) => (
                  <MotionTableRow
                    key={review.id}
                    variants={rowVariants}
                    initial="hidden"
                    animate="visible"
                    style={{ transitionDelay: `${index * 0.1}s` }}
                  >
                    <TableCell>
                      {review.userId === user?.id ? (
                        <StyledFollowText bold>
                          {'Owned'.toLocaleUpperCase()}
                        </StyledFollowText>
                      ) : filterType === ReviewFilter.LATEST ? (
                        <StyledFollowText bold>
                          {'Public'.toLocaleUpperCase()}
                        </StyledFollowText>
                      ) : (
                        <StyledFollowText bold>
                          {'Followed'.toLocaleUpperCase()}
                        </StyledFollowText>
                      )}
                    </TableCell>

                    <TableCell>
                      <StyledFollowText bold>
                        {review.reviewDepth === ReviewDepth.BASIC
                          ? 'Basic'.toLocaleUpperCase()
                          : 'Deep-Dive'.toLocaleUpperCase()}
                      </StyledFollowText>
                    </TableCell>
                    <TableCell
                      align="left"
                      onContextMenu={(event) =>
                        handleContextMenu(event, review)
                      }
                    >
                      <CompanyImage
                        onClick={() =>
                          navToCompany(review?.companyName?.toString() || '')
                        }
                        src={`https://kandir-images.s3.eu-west-2.amazonaws.com/Company Logos/${review.companyName}.jpeg`}
                        onError={defaultImage}
                      />
                      <StyledCompanyName>
                        {review.companyName}
                      </StyledCompanyName>
                    </TableCell>
                    <TableCell align="center">
                      {RATING_MAPPINGS.map((rating) => {
                        return (
                          <RatingWrapper key={rating.label}>
                            <StyledRatingText bold>
                              {rating.label}
                            </StyledRatingText>
                            <RatingUI
                              readOnly
                              size="small"
                              value={Number(
                                review.ratings.find(
                                  (test: RatingSummary) =>
                                    test.type === rating.name
                                )?.rating
                              )}
                            />
                          </RatingWrapper>
                        );
                      })}
                    </TableCell>
                    <TableCell>
                      <P>{dayjs(review.createdAt).format('MM/DD/YYYY')}</P>
                    </TableCell>
                    <TableCell>
                      <Button
                        testId="go-to-review-button"
                        size="medium"
                        onClick={() => handleReviewClick(review)}
                      >
                        Go to Review{' '}
                        {user?.planSlug !== 'team' &&
                          !(
                            review.userId === user?.id ||
                            review.reviewDepth === ReviewDepth.BASIC
                          ) && (
                            <DynamicIcon
                              iconName={
                                review.viewed
                                  ? IconName.Visibility
                                  : IconName.Lock
                              }
                              size="small"
                            />
                          )}
                      </Button>
                    </TableCell>
                  </MotionTableRow>
                ))}
              </TableBody>
            </>
          )}
        </Table>
        {contextMenu && (
          <ContextMenu
            contextMenu={contextMenu}
            handleClose={handleClose}
            menuItems={[
              {
                label: `Open ${contextMenu.review?.companyName || 'Company'} profile in new tab`,
                action: () =>
                  contextMenu.review && handleOpenProfile(contextMenu.review),
              },
            ]}
          />
        )}
      </TableContainer>
      <ReviewUnlockModal
        open={deepDiveReviewLimitModal}
        onCancel={() => setDeepDiveReviewLimitModal(false)}
        maxConsumableDeepDivesInSubPeriod={
          user?.maxConsumableDeepDivesInSubPeriod ?? 0
        }
        consumedDeepDives={user?.deepDivesViewedInSubPeriod ?? 0}
        onProceed={() => {
          navigate(
            `/review-reader/${navToCompanyName}/${String(navToReviewId)}`
          );
        }}
        cancelText="Back to Review Feed"
      />
    </>
  );
};

export default React.memo(ReviewTable);
