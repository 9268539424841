import { Modal } from '@mui/material';
import styled from 'styled-components';
import { H3, P } from './Typography';

export const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 16px;
  padding: 32px;
  width: 500px;
  max-width: 90%;
  text-align: center;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.3s ease-in-out;
  outline: 0;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const ModalHeader = styled(H3)`
  font-weight: bold;
  color: #333;
  margin-bottom: var(--spacing-sm);
  text-align: center;
`;

export const ModalText = styled(P)`
  font-size: 16px;
  color: #555;
  margin-bottom: 32px;
  text-align: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: var(--spacing-sm);
  margin-top: var(--spacing-sm);
`;
