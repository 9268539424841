import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { ReviewStep, RfpReview } from '../../../../shared/domain/review';
import { RFP_FORM_FIELDS } from '../../data';
import { Container } from '../../styles/review.styles';
import ReviewFormButtons from '../ReviewFormButtons';
import { useReviewContext } from '../../context/ReviewContext';
import useValidation from '../../hooks/useValidation';
import {
  MultipleChoiceQuestion,
  QuestionType,
  TextQuestion,
} from '../../../../shared/domain/form';
import { shouldShowQuestion } from '../../helpers/ReviewFormHelpers';
import MultipleChoiceReviewQuestion from '../question_types/ReviewMultipleChoiceQuestion';
import ReviewTextArea from '../question_types/ReviewTextQuestion';

type RfpReviewProps = {
  submitReviewForm: (
    e: React.FormEvent<HTMLFormElement>,
    formFields: RfpReview,
    reviewStep: ReviewStep,
    isDraft?: boolean
  ) => void;
};

const RfpReviewForm = (props: RfpReviewProps) => {
  const { submitReviewForm } = props;

  const { currentReview } = useReviewContext();

  const [formFields, setFormFields] = useState<RfpReview>(
    currentReview || ({} as RfpReview)
  );

  const { errors, validateFields, validateField } = useValidation(
    formFields,
    RFP_FORM_FIELDS
  );

  const handleFormFieldChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setFormFields({ ...formFields, [name]: value });
    validateField(name, value);
  };

  const submitRfpReviewForm = (e: any) => {
    e.preventDefault();
    const hasErrors = validateFields();
    if (hasErrors) {
      return;
    }
    submitReviewForm(e, formFields, ReviewStep.RFP, true);
  };

  useEffect(() => {
    setFormFields(currentReview ?? ({} as RfpReview));
  }, [currentReview]);

  return (
    <Container>
      <form>
        <Grid container>
          {RFP_FORM_FIELDS.filter(
            (q): q is MultipleChoiceQuestion =>
              q.type === QuestionType.MULTIPLE_CHOICE
          ).map((question) => (
            <Grid item xs={12} sm={6}>
              <MultipleChoiceReviewQuestion
                data-error-name={question.name}
                key={question.name}
                errors={errors}
                question={question}
                formFields={formFields}
                handleFormFieldChange={handleFormFieldChange}
                isNarrow={question.options.length > 8}
              />
            </Grid>
          ))}
          {RFP_FORM_FIELDS.filter(
            (q): q is TextQuestion => q.type === QuestionType.TEXT
          )
            .filter((question) => shouldShowQuestion(question, formFields))
            .map((question, i) => (
              <ReviewTextArea
                key={question.name}
                question={question}
                formFields={formFields}
                handleFormFieldChange={handleFormFieldChange}
                errors={errors}
                index={i}
              />
            ))}
        </Grid>

        <ReviewFormButtons
          handleContinueReview={(e: React.FormEvent<HTMLFormElement>) =>
            submitRfpReviewForm(e)
          }
          nextButtonText="Complete Deep Dive Review"
        />
      </form>
    </Container>
  );
};

export default RfpReviewForm;
