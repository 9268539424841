import React, {
  createContext,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { authService } from '../services/AuthService';
import { User } from '../../scenes/authentication/types/types.auth';

export type UserContextType = {
  user: User | null;
  setUser: Dispatch<SetStateAction<User | null>>;
  updateUser: (
    value: User | null | ((prevUser: User | null) => User)
  ) => Promise<void>;
  reload: number;
  setReload: Dispatch<SetStateAction<number>>;
  fetchDeepDiveReviewCount: () => Promise<void>;
  userLoaded: boolean;
};

export const UserContext = createContext<UserContextType>({
  user: null,
  setUser: () => {},
  updateUser: async () => {},
  reload: 0,
  setReload: () => {},
  fetchDeepDiveReviewCount: async () => {},
  userLoaded: false,
});

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setRawUser] = useState<User | null>(null);
  const [reload, setReload] = useState<number>(0);
  const [userLoaded, setUserLoaded] = useState<boolean>(false);

  const navigate = useNavigate();

  const updateUser = async (
    value: User | null | ((prevUser: User | null) => User)
  ) => {
    try {
      const updatedUser = typeof value === 'function' ? value(user) : value;
      if (!updatedUser) {
        setRawUser(null);
        return;
      }

      const session = await authService.refreshSession();
      const finalUser = {
        ...updatedUser,
        idToken: session.getIdToken().getJwtToken(),
        accessToken: session.getAccessToken().getJwtToken(),
      };

      setRawUser(finalUser);
    } catch (error) {
      setRawUser(value instanceof Function ? value(user) : value);
      navigate('/sign-in');
    }
  };

  const loadUserSubscriptionUsage = useCallback(async () => {
    if (!user?.idToken || user.deepDivesViewedInSubPeriod !== undefined) return;

    try {
      const response = await axios.get(
        `${import.meta.env.VITE_API_BASE_URL}/subscription/usage`,
        {
          headers: {
            Authorization: user?.idToken,
          },
        }
      );

      await updateUser((prevUser: User | null) => {
        if (!prevUser) return {} as User;
        return {
          ...prevUser,
          planSlug: response.data.subscription.plan,
          deepDivesViewedInSubPeriod:
            response.data.usage.deepDivesViewedInSubPeriod,
          planDeepDiveReviewLimit:
            response.data.subscription.features.numberOfDeepDiveViews,
          deepDivesSubmittedInSubPeriod:
            response.data.usage.deepDivesSubmittedInSubPeriod,
          deepDivesAvailableInSubPeriod:
            response.data.usage.deepDivesAvailableInSubPeriod,
          maxConsumableDeepDivesInSubPeriod:
            response.data.subscription.features.numberOfDeepDiveViews +
            response.data.usage.deepDivesSubmittedInSubPeriod,
        };
      });
    } catch (error) {
      console.log('Error loading deep dive review count:', error);
    }
  }, [user?.idToken]);

  useEffect(() => {
    if (user?.idToken) {
      loadUserSubscriptionUsage();
    }
  }, [user?.idToken]);

  useEffect(() => {
    const loadUser = async () => {
      try {
        const currentUser = await authService.getCurrentUser();
        if (currentUser) {
          await updateUser(currentUser);
        }
      } catch (error) {
        console.error('Error loading user:', error);
      } finally {
        setUserLoaded(true);
      }
    };

    loadUser();
  }, []);

  useEffect(() => {
    loadUserSubscriptionUsage();
  }, [user?.idToken]);

  useEffect(() => {
    const refreshInterval = 55 * 60 * 1000;

    const refreshTokens = async () => {
      try {
        await authService.refreshSession();
        loadUserSubscriptionUsage();
      } catch (error) {
        console.error('Error refreshing tokens:', error);
      }
    };

    const intervalId = setInterval(refreshTokens, refreshInterval);
    return () => clearInterval(intervalId);
  }, []);

  const fetchDeepDiveReviewCount = async () => {
    try {
      await loadUserSubscriptionUsage();
    } finally {
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser: setRawUser,
        updateUser,
        reload,
        setReload,
        fetchDeepDiveReviewCount,
        userLoaded,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
