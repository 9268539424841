import React from 'react';
import { Typography, Box } from '@mui/material';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const NotFoundContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #fff;
  border-radius: 12px;
  padding: var(--spacing-md);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  width: 100%;
  max-width: 600px;
  margin: auto;
  border: 0.5px solid #2b90e3;
`;

const Message = styled(Typography)`
  font-size: 18px;
  font-weight: bold;
  color: #2b90e3;
  margin-bottom: var(--spacing-sm);
`;

const SubMessage = styled(Typography)`
  font-size: 16px;
  color: #666;
`;

const fadeInVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: 'easeOut' } },
};

const ReviewNotFound = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    minHeight="50vh"
  >
    <NotFoundContainer
      initial="hidden"
      animate="visible"
      variants={fadeInVariants}
    >
      <Message>Review Not Found</Message>
      <SubMessage>We couldn't find the review you're looking for.</SubMessage>
    </NotFoundContainer>
  </Box>
);

export default ReviewNotFound;
