import React from 'react';
import { ShortTextQuestion } from '../../../../shared/domain/form';
import ErrorMessage from '../../../../shared/components/ErrorMessage';
import { StyledTextField } from '../../styles/review.styles';
import {
  QuestionWrapper,
  QuestionTextWrapper,
  StyledQuestionText,
} from './ReviewMultipleChoiceQuestion';

interface ReviewShortQuestionProps {
  question: ShortTextQuestion;
  formFields: Record<string, any>;
  handleFormFieldChange: (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  errors: Record<string, string>;
}

const ReviewShortTextQuestion = (props: ReviewShortQuestionProps) => {
  const { question, formFields, handleFormFieldChange, errors } = props;

  return (
    <QuestionWrapper data-error-name={question.name}>
      <QuestionTextWrapper>
        <StyledQuestionText>{question.question}</StyledQuestionText>
      </QuestionTextWrapper>
      <div style={{ width: '80%' }}>
        <StyledTextField
          name={question.name}
          onChange={handleFormFieldChange}
          value={formFields[question.name] || ''}
          size="small"
        />
        {errors[question.name] && (
          <ErrorMessage error={errors[question.name]} />
        )}
      </div>
    </QuestionWrapper>
  );
};

export default ReviewShortTextQuestion;
