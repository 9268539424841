import { Route, Routes } from 'react-router-dom';
import SignUp from './scenes/authentication/Authentication.component';
import Navbar from './shared/components/Navbar';
import WelcomePage from './scenes/welcome/WelcomePage';
import ForgotPassword from './scenes/authentication/components/ForgotPassword';
import NotFoundPage from './shared/components/NotFoundPage';
import SettingsPage from './scenes/account-settings/SettingsPage';
import CompanySearchPage from './scenes/company-search/CompanySearchPage';
import CompanyProfile from './scenes/company-search/components/CompanyProfile';
import PreLogin from './scenes/pre-login/PreLogin';
import TermsOfUse from './scenes/pre-login/terms-of-use/TermsOfUse.jsx';
import PrivacyPolicy from './scenes/pre-login/privacy/PrivacyPolicy';
import { Main } from './shared/components/SideDrawer';
import { useState } from 'react';
import './App.css';
import AdminPage from './scenes/admin/AdminPage';
import Pricing from './scenes/pre-login/pricing/Pricing';
import Footer from './shared/components/Footer';
import ScrollToTop from './shared/components/ScrollToTop';
import { useResponsive } from './shared/hooks/useResponsive';
import ReviewReader from './scenes/review-reader/ReviewReader';

const App = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const { isMobile } = useResponsive();
  const transparent = window.location.pathname !== '/';
  return (
    <>
      <ScrollToTop />
      <Navbar
        setIsNavOpen={setIsNavOpen}
        isNavOpen={isNavOpen}
        transparent={transparent}
      />
      <Main open={isNavOpen} isMobile={isMobile}>
        <Routes>
          <Route element={<SignUp />} />
          {['/sign-in', '/sign-up', '/verification'].map((path, index) => (
            <Route path={path} element={<SignUp />} key={index} />
          ))}
          <Route path="/" element={<PreLogin setIsNavOpen={setIsNavOpen} />} />
          <Route
            path="/welcome"
            element={<WelcomePage setIsNavOpen={setIsNavOpen} />}
          />
          <Route path="/terms" element={<TermsOfUse />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/account-settings" element={<SettingsPage />} />
          <Route path="/company-search" element={<CompanySearchPage />} />
          <Route path="/company-search/:name" element={<CompanyProfile />} />
          <Route
            path="/review-reader/:name/:reviewId"
            element={<ReviewReader />}
          />

          <Route path="/admin" element={<AdminPage />} />
          <Route path="/*" element={<NotFoundPage />} />
        </Routes>
      </Main>
      <Footer />
    </>
  );
};

export default App;
