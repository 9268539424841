import React, { useContext, useState } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { Rating } from '@mui/material';
import { motion } from 'framer-motion';
import { BasicReview, ReviewDepth } from '../../../shared/domain/review';
import { H4, P, screenMdMin } from '../../../shared/styles';
import ReviewHeader, {
  CardLabel,
  CardValue,
  InfoCard,
  SectionTitle,
} from './ReviewHeader';
import { RATING_MAPPINGS } from '../../../shared/mapping/review';
import { UserContext } from '../../../shared/contexts/UserContext';
import ReviewUnlockModal from '../../review/components/DeepDiveReviewCountModal';
import ReviewButton from '../../review/components/ReviewButton';
import { IconName } from '../../../shared/components/Icon';

type CompanyReviewProps = {
  review: BasicReview;
  handleOpenReviewDialog: (id: number) => void;
  handleDeepDiveClick?: (review: BasicReview) => void;
  userId: number;
};

const ReviewWrapper = styled(motion.div)`
  background: #ffffff;
  border-radius: 12px;
  padding: var(--spacing-md);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: var(--spacing-md);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  position: relative;
`;

const SectionHeader = styled(H4)`
  margin: 0;
  padding: 0;
  color: #333;
  text-decoration: underline;
  font-size: 1.4rem;
`;

const DateText = styled(P)`
  font-size: 14px;
  color: #666;
  text-align: left;
`;

const RatingsGrid = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px;

  @media (max-width: ${screenMdMin}) {
    flex-direction: column;
    gap: 12px;
    align-items: center;
  }
`;

const RatingBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 8px;
`;

const RatingLabel = styled(P)`
  font-weight: bold;
  color: #333;
  font-size: 16px;
`;

const LargeRating = styled(Rating)`
  && {
    font-size: 2.6rem;
  }
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-md);

  @media ${screenMdMin} {
    display: flex;
  }
`;

const Label = styled(P)`
  font-weight: bold;
  color: #333;
  margin-bottom: 8px;
  font-size: 1rem;
`;

const InfoText = styled(P)`
  color: #666;
  font-size: 0.95rem;
  line-height: 1.5;
`;

const CommentSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const CommentBox = styled.div`
  background: #f9f9f9;
  padding: 14px;
  border-radius: 10px;
  border: 1px solid #eee;
  font-size: 1rem;
  line-height: 1.4;
`;

const CompanyReview = (props: CompanyReviewProps) => {
  const { review, handleOpenReviewDialog, handleDeepDiveClick, userId } = props;
  const [modalOpen, setModalOpen] = useState(false);

  const { user } = useContext(UserContext);

  const handleModalProceed = () => {
    setModalOpen(false);
    if (review.id) {
      handleOpenReviewDialog(review.id);
    }
  };

  return (
    <ReviewWrapper
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 }}
    >
      <ReviewHeader userId={userId} />
      <DateText>
        Date Created: {dayjs(review?.createdAt).format('MM/DD/YYYY')}
      </DateText>

      <SectionTitle>Deal Information</SectionTitle>
      <InfoGrid>
        <InfoCard>
          <CardLabel>What type of deal was it?</CardLabel>
          <CardValue>{review?.dealType}</CardValue>
        </InfoCard>
        <InfoCard>
          <CardLabel>How much was this deal worth?</CardLabel>
          <CardValue>{review?.dealSize}</CardValue>
        </InfoCard>
        <InfoCard>
          <CardLabel>Did the seller win the deal?</CardLabel>
          <CardValue>{review?.dealClose}</CardValue>
        </InfoCard>
        <InfoCard>
          <CardLabel>
            What part of the organization did the seller sell to?
          </CardLabel>
          <CardValue>{review?.dealArea}</CardValue>
        </InfoCard>
      </InfoGrid>

      <SectionHeader>Comments</SectionHeader>
      <CommentSection>
        <CommentBox>
          <Label>What did the buyer do well?</Label>
          <InfoText>{review?.commentBuyerWell}</InfoText>
        </CommentBox>
        <CommentBox>
          <Label>What could the buyer have done better?</Label>
          <InfoText>{review?.commentBuyerBetter}</InfoText>
        </CommentBox>
        <CommentBox>
          <Label>What advice do you have for the next seller?</Label>
          <InfoText>{review?.commentAdvice}</InfoText>
        </CommentBox>
      </CommentSection>

      <SectionHeader>Ratings</SectionHeader>
      <RatingsGrid>
        {review?.ratings &&
          RATING_MAPPINGS.map((rating) => (
            <RatingBox key={rating.label}>
              <RatingLabel>{rating.shortLabel || rating.label}</RatingLabel>
              <LargeRating
                readOnly
                size="large"
                value={Number(
                  review.ratings.find(
                    (r: any) => r.type === rating.responseName
                  )?.rating
                )}
              />
            </RatingBox>
          ))}
      </RatingsGrid>

      {review.reviewDepth === ReviewDepth.DEEP_DIVE && (
        <ReviewButton
          onClick={() => handleDeepDiveClick && handleDeepDiveClick(review)}
          iconName={
            user?.planSlug === 'team'
              ? null
              : review.viewed || user?.id === review.userId
                ? IconName.LockOpen
                : IconName.Lock
          }
          text="Deep Dive Review Available"
        />
      )}

      {user?.deepDivesViewedInSubPeriod !== undefined &&
        user?.deepDivesViewedInSubPeriod !== null &&
        user.deepDivesViewedInSubPeriod > 0 && (
          <ReviewUnlockModal
            open={modalOpen}
            onCancel={() => setModalOpen(false)}
            maxConsumableDeepDivesInSubPeriod={
              user?.maxConsumableDeepDivesInSubPeriod ?? 0
            }
            consumedDeepDives={user.deepDivesViewedInSubPeriod}
            cancelText="Back to Company Profile"
            onProceed={handleModalProceed}
          />
        )}
    </ReviewWrapper>
  );
};

export default CompanyReview;
