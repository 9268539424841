import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { Container } from '../../shared/components/Container';
import LoadingProgress from '../../shared/components/LoadingProgress';
import CustomSnackbar, {
  SnackBarConfig,
} from '../../shared/components/Snackbar';
import { UserContext } from '../../shared/contexts/UserContext';
import FormWrapper from './components/AuthFormWrapper.component';
import {
  AuthEnum,
  AuthFormFieldsValues,
  SIGN_IN_FORM_FIELDS,
  SIGN_UP_FORM_FIELDS,
  User,
  VERIFICATION_FORM_FIELDS,
} from './types/types.auth';
import { showErrorSnackbar } from './utils/auth.utils';
import { isEmailRegistered } from './utils/isEmailRegistered';
import { authService } from '../../shared/services/AuthService';

type OptionType = {
  label: string;
  value: string;
};

const initialFormfields = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  company: '',
  positionTitle: '',
  verificationCode: '',
  industry: '',
  revenue: '',
  companyName: '',
  companySize: '',
};

const Authentication = () => {
  const [formFields, setFormFields] = useState<AuthFormFieldsValues | any>(
    initialFormfields
  );
  const [loading, setLoading] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState<SnackBarConfig>();
  const [userNotConfirmed, setUserNotConfirmed] = useState(false);

  const { setUser } = useContext(UserContext);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  let authType: AuthEnum = location.pathname.includes('verification')
    ? AuthEnum.VERIFICATION
    : location.pathname.includes('sign-up')
      ? AuthEnum.SIGN_UP
      : AuthEnum.SIGN_IN;

  const handleSnackBarClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  const handleFormFieldChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    selectedOptions?: any
  ) => {
    const { name, value } = e.target;

    if (selectedOptions) {
      const selectedValues = (selectedOptions as OptionType[]).map(
        (option) => option.value
      );
      setFormFields({ ...formFields, [name]: selectedValues });
    } else {
      setFormFields({ ...formFields, [name]: value });
    }
  };

  const createUser = async () => {
    const userInput: User = {
      firstName: formFields.firstName,
      lastName: formFields.lastName,
      isAdmin: false,
      email: formFields.email,
      company: formFields.company,
      positionTitle: formFields.positionTitle,
      industry: formFields.industry,
      companySize: formFields.companySize,
    };
    try {
      await axios.post(`${import.meta.env.VITE_API_BASE_URL}/user`, userInput);
    } catch (error) {
      setSnackbarConfig(showErrorSnackbar(error.message));
    }
  };

  const handleSubmitSignUp = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    try {
      const doesUserExist = await isEmailRegistered(formFields.email);
      if (doesUserExist !== null) {
        throw new Error('An account with the given email already exists');
      }

      await authService.signUpUser(formFields.email, formFields.password);
      navigate('/verification');
    } catch (error) {
      setSnackbarConfig(showErrorSnackbar(error.message));
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitSignIn = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    try {
      const result = await authService.authenticateUser(
        formFields.email,
        formFields.password
      );
      if ('newPasswordRequired' in result) {
        try {
          const userPayload = await authService.completeNewPasswordChallenge(
            formFields.email,
            formFields.password,
            {}
          );
          setUser(userPayload);

          navigate('/welcome');
        } catch (error) {
          setSnackbarConfig(showErrorSnackbar(error.message));
        }
      } else {
        setUser(result);
        if (queryParams.get('reviewId')) {
          navigate({
            pathname: `/review-reader/${queryParams.get('companyName')}/${queryParams.get('reviewId')}`,
          });
          return;
        } else {
          navigate('/welcome');
        }
      }
    } catch (error) {
      if (error.message && error.message.includes('not confirmed')) {
        setUserNotConfirmed(true);
      } else {
        setSnackbarConfig(showErrorSnackbar(error.message));
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitVerification = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();

    if (!formFields.verificationCode) return;

    setLoading(true);

    try {
      await authService.confirmRegistration(
        formFields.email,
        formFields.verificationCode,
        true
      );

      setSnackbarConfig({
        message: 'Account successfully verified!',
        open: true,
        type: 'success',
      });

      try {
        const userPayload = await authService.authenticateUser(
          formFields.email,
          formFields.password
        );
        if ('newPasswordRequired' in userPayload) {
          throw new Error('New password required after verification');
        }
        await createUser();
        setUser(userPayload);

        navigate({
          pathname: '/welcome',
          search: `?${createSearchParams({
            verified: 'true',
          })}`,
        });
      } catch (error) {
        setSnackbarConfig(showErrorSnackbar(error.message));
      }
    } catch (error) {
      setSnackbarConfig(showErrorSnackbar(error.message));
    } finally {
      setLoading(false);
    }
  };

  const handleResendVerificationCode = async () => {
    setLoading(true);
    try {
      await authService.resendConfirmationCode(formFields.email);
      setSnackbarConfig({
        message: 'Verification code has been resent to your email!',
        open: true,
        type: 'info',
      });
    } catch (error) {
      setSnackbarConfig(showErrorSnackbar(error.message));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (queryParams.get('reAuth')) {
      setSnackbarConfig({
        message: 'Please sign in to continue.',
        open: true,
        type: 'info',
      });
    }
  }, []);

  if (loading) {
    return <LoadingProgress />;
  }

  return (
    <Container>
      {authType === AuthEnum.SIGN_IN && (
        <FormWrapper
          handleFormFieldChange={handleFormFieldChange}
          formFields={SIGN_IN_FORM_FIELDS}
          handleSubmit={handleSubmitSignIn}
          title="Sign In"
          authType={AuthEnum.SIGN_IN}
          defaultValues={formFields}
          buttonText="Sign In"
          userNotConfirmed={userNotConfirmed}
          handleResendVerificationCode={handleResendVerificationCode}
        />
      )}
      {authType === AuthEnum.SIGN_UP && (
        <FormWrapper
          handleFormFieldChange={handleFormFieldChange}
          formFields={SIGN_UP_FORM_FIELDS}
          handleSubmit={handleSubmitSignUp}
          title="Sign Up"
          authType={AuthEnum.SIGN_UP}
          defaultValues={formFields}
          buttonText="Sign Up"
        />
      )}

      {authType === AuthEnum.VERIFICATION && (
        <FormWrapper
          handleFormFieldChange={handleFormFieldChange}
          handleResendVerificationCode={handleResendVerificationCode}
          formFields={VERIFICATION_FORM_FIELDS}
          handleSubmit={handleSubmitVerification}
          title="Verification"
          authType={AuthEnum.VERIFICATION}
          buttonText="Verify"
        />
      )}
      <CustomSnackbar config={snackbarConfig} setOpen={handleSnackBarClose} />
    </Container>
  );
};

export default Authentication;
