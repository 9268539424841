import React, { useContext, useEffect, useState } from 'react';
import BaseModal from '../../../shared/components/BaseModal';
import { P } from '../../../shared/styles';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import styled from 'styled-components';
import { ReviewData } from '../types/admin.types';
import axios from 'axios';
import CustomSnackbar, {
  SnackBarConfig,
} from '../../../shared/components/Snackbar';
import { Company } from '../../authentication/types/types.auth';
import { UserContext } from '../../../shared/contexts/UserContext';

type MigrateReviewsModalProps = {
  isOpen: boolean;
  setIsOpen: () => void;
  reviewData: Array<ReviewData>;
  migrateReviews?: (
    e: React.MouseEvent<HTMLElement>,
    email: string,
    name?: string
  ) => void;
};

const StyledSelect = styled(Select)`
  margin: var(--spacing-md) 0;
`;

const MigrateReviewsModal = (props: MigrateReviewsModalProps) => {
  const { isOpen, setIsOpen, reviewData } = props;
  const [snackbarConfig, setSnackbarConfig] = useState<SnackBarConfig>();
  const [companies, setCompanies] = useState<Array<Company>>();
  const [selectedReviews, setSelectedReviews] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');

  const { user } = useContext(UserContext);

  const handleReviewSelectChange = (event: any) => {
    setSelectedReviews(event.target.value);
  };

  const handleCompanySelectChange = (event: SelectChangeEvent<unknown>) => {
    setSelectedCompany(event.target.value as string);
  };

  const handleSnackBarClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  const getCompanies = async () => {
    try {
      const res = await axios.post(
        `${import.meta.env.VITE_API_BASE_URL}/company/search`
      );
      if (Array.isArray(res.data)) {
        setCompanies(res.data);
      }
    } catch (error) {
      setSnackbarConfig({
        open: true,
        type: 'error',
        message: 'An error has occurred, please try again later',
      });
    }
  };

  const migrateReviewsHandler = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    try {
      const params = {
        reviewIds: selectedReviews,
        companyId: selectedCompany,
      };
      await axios.put(
        `${import.meta.env.VITE_API_BASE_URL}/reviews/update-review`,
        params,
        {
          headers: {
            Authorization: user?.idToken,
          },
        }
      );

      setSnackbarConfig({
        open: true,
        type: 'success',
        message: 'Reviews have been successfully migrated',
      });

      setIsOpen();
    } catch (error) {
      setSnackbarConfig({
        open: true,
        type: 'error',
        message: 'An error has occurred, please try again later',
      });
    }
  };

  useEffect(() => {
    getCompanies();
  }, []);

  return (
    <BaseModal
      open={isOpen}
      handleClose={setIsOpen}
      title="Migrate Reviews"
      handleSubmit={(e) => migrateReviewsHandler(e)}
    >
      <P>
        First select all the reviews (by ID) you wish to migrate to a new
        company:
      </P>
      <StyledSelect
        multiple
        fullWidth
        value={selectedReviews}
        onChange={handleReviewSelectChange}
      >
        {reviewData.map((review: ReviewData) => (
          <MenuItem value={review.reviewId} key={review.id}>
            {review.reviewId}
          </MenuItem>
        ))}
      </StyledSelect>
      <P>Now please select the company that you wish to migrate them to:</P>
      <StyledSelect
        fullWidth
        variant="outlined"
        value={selectedCompany}
        onChange={handleCompanySelectChange}
      >
        {companies?.map((company: Company) => (
          <MenuItem value={company.id} key={company.id}>
            {company.companyName}
          </MenuItem>
        ))}
      </StyledSelect>
      <CustomSnackbar config={snackbarConfig} setOpen={handleSnackBarClose} />
    </BaseModal>
  );
};

export default MigrateReviewsModal;
