import { useContext, useEffect, useState } from 'react';
import { FormControlLabel, Switch, Typography, Box } from '@mui/material';
import axios from 'axios';
import { UserContext } from '../../../shared/contexts/UserContext';
import CustomSnackbar, {
  SnackBarConfig,
} from '../../../shared/components/Snackbar';

const UpdatePreferencesForm = () => {
  const { user } = useContext(UserContext);
  const [showAIValidation, setShowAIValidation] = useState(true);
  const [snackbarConfig, setSnackbarConfig] = useState<SnackBarConfig>();

  useEffect(() => {
    fetchPreferences();
  }, []);

  const fetchPreferences = async () => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_API_BASE_URL}/user/${user?.id}/preferences`,
        {
          headers: {
            Authorization: user?.idToken,
          },
        }
      );
      setShowAIValidation(response.data.showAIValidation);
    } catch (error) {
      console.error('Error fetching preferences:', error);
      setSnackbarConfig({
        type: 'error',
        message: 'Failed to load preferences. Please try again.',
        open: true,
      });
    }
  };

  const handleToggle = async () => {
    try {
      await axios.put(
        `${import.meta.env.VITE_API_BASE_URL}/user/${user?.id}/updatePreferences`,
        {
          preferences: {
            showAIValidation: !showAIValidation,
          },
        },
        {
          headers: {
            Authorization: user?.idToken,
            'Content-Type': 'application/json',
          },
        }
      );
      setShowAIValidation(!showAIValidation);
      setSnackbarConfig({
        type: 'success',
        message: 'Preferences updated successfully',
        open: true,
      });
    } catch (error) {
      console.error('Error updating preferences:', error);
      setSnackbarConfig({
        type: 'error',
        message: 'Failed to update preferences. Please try again.',
        open: true,
      });
    }
  };

  const handleSnackBarClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        AI Preferences
      </Typography>
      <FormControlLabel
        control={
          <Switch
            checked={showAIValidation}
            onChange={handleToggle}
            name="showAIValidation"
          />
        }
        label="Show AI Validation"
      />
      <CustomSnackbar config={snackbarConfig} setOpen={handleSnackBarClose} />
    </Box>
  );
};

export default UpdatePreferencesForm;
