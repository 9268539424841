import React from 'react';
import { Grid } from '@mui/material';
import {
  DraggableListQuestion,
  MultipleChoiceQuestion,
  QuestionType,
  TextQuestion,
} from '../../../../shared/domain/form';
import { ReviewStep, EvaluationReview } from '../../../../shared/domain/review';
import { useExtendState } from '../../../../shared/utils/ExtendState';
import { useReviewContext } from '../../context/ReviewContext';
import { EVALUATION_FORM_FIELDS } from '../../data';
import { shouldShowQuestion } from '../../helpers/ReviewFormHelpers';
import useValidation from '../../hooks/useValidation';
import { Container } from '../../styles/review.styles';

import MultipleChoiceReviewQuestion from '../question_types/ReviewMultipleChoiceQuestion';
import ReviewTextArea from '../question_types/ReviewTextQuestion';
import ReviewFormButtons from '../ReviewFormButtons';
import ReviewDraggableListQuestion from '../question_types/ReviewDraggableListQuestion';

type EvaluationReviewProps = {
  submitReviewForm: (
    e: React.FormEvent<HTMLFormElement>,
    formFields: EvaluationReview,
    reviewStep: ReviewStep,
    isDraft?: boolean
  ) => void;
};

const nextButtonText = 'Move onto Legal';

const EvaluationReviewForm = (props: EvaluationReviewProps) => {
  const { submitReviewForm } = props;

  const { currentReview } = useReviewContext();

  const [formFields, extendFormFields, setFormFields] =
    useExtendState<EvaluationReview>(currentReview ?? ({} as EvaluationReview));

  const { errors, validateFields } = useValidation(
    formFields,
    EVALUATION_FORM_FIELDS
  );

  const handleFormFieldChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    extendFormFields({
      ...formFields,
      [name]: value,
    });
  };

  const submitEvaluationReview = async (e: any) => {
    e.preventDefault();
    const hasErrors = validateFields();
    if (hasErrors) {
      return;
    }

    submitReviewForm(e, formFields, ReviewStep.EVALUATION, true);
  };

  return (
    <Container>
      <form>
        <Grid container>
          {EVALUATION_FORM_FIELDS.filter(
            (q): q is MultipleChoiceQuestion =>
              q.type === QuestionType.MULTIPLE_CHOICE
          )
            .filter((question) => shouldShowQuestion(question, formFields))
            .map((question) => (
              <MultipleChoiceReviewQuestion
                key={question.name}
                data-error-name={question.name}
                question={question}
                formFields={formFields}
                handleFormFieldChange={handleFormFieldChange}
                errors={errors}
                isNarrow={question.options.length > 8}
              />
            ))}
        </Grid>
        <Grid container>
          {EVALUATION_FORM_FIELDS.filter(
            (q): q is TextQuestion => q.type === QuestionType.TEXT
          )
            .filter((question) => shouldShowQuestion(question, formFields))
            .map((question, i) => (
              <ReviewTextArea
                key={question.name}
                data-error-name={question.name}
                question={question}
                formFields={formFields}
                handleFormFieldChange={handleFormFieldChange}
                errors={errors}
                index={i}
              />
            ))}
        </Grid>
        <Grid container spacing={2}>
          {EVALUATION_FORM_FIELDS.filter(
            (dl): dl is DraggableListQuestion =>
              dl.type === QuestionType.DRAGGABLE_LIST
          ).map((question, i) => (
            <ReviewDraggableListQuestion
              key={question.name}
              question={question}
              formFields={formFields}
              handleFormFieldChange={setFormFields}
            />
          ))}
        </Grid>

        <ReviewFormButtons
          handleContinueReview={(e: React.FormEvent<HTMLFormElement>) =>
            submitEvaluationReview(e)
          }
          nextButtonText={nextButtonText}
        />
      </form>
    </Container>
  );
};

export default EvaluationReviewForm;
