import React from 'react';
import { Typography, Box } from '@mui/material';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import SectionHeaderReader from './components/SectionHeaderReader';
import RatingsReader from './components/RatingsReader';
import ReviewNotFound from './components/ReviewNotFound';
import ApproversListCard from './components/ReviewApproversListCard';
import DynamicIcon, { IconName } from '../../shared/components/Icon';
import { Breakpoints } from '../../shared/styles';
import ReviewReaderBasicQuestion from './ReviewReaderBasicQuestion';
import { ReviewAnswer, ReviewSection } from './types/types';
import { useResponsive } from '../../shared/hooks/useResponsive';

const fadeInVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: 'easeOut' } },
};

const MotionContainer = motion(Box);

const InfoContainer = styled(MotionContainer)`
  background: #fff;
  border-radius: var(--border-radius);
  padding: var(--spacing-md);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  width: 100%;
  max-width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  border: 0.5px solid #2b90e3;

  @media (max-width: ${Breakpoints.md}) {
    padding: var(--spacing-sm);
  }
`;

const ListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: var(--spacing-md) 0;
  @media (max-width: ${Breakpoints.md}) {
    flex-direction: column;
  }
`;

const ReviewContainer = styled.div`
  padding: var(--spacing-md);
  width: 100%;
`;

const CommentGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-sm);
  margin-top: var(--spacing-sm);

  @media (max-width: ${Breakpoints.md}) {
    grid-template-columns: 1fr;
  }
`;
const BasicInfoWrapper = styled(Box)`
  margin: var(--spacing-md) 0;
`;

const StyledCommentContainer = styled(Box)`
  background: #f9fbfd;
  border-left: 4px solid #2b90e3;
  padding: var(--spacing-sm);
  border-radius: var(--border-radius);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  min-height: 120px;
`;

const StyledCommentTitle = styled(Typography)`
  font-weight: bold;
  font-size: 16px;
  color: #2b90e3;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledCommentText = styled(Typography)`
  font-size: 15px;
  color: #444;
  line-height: 1.6;
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  font-style: italic;
`;

const CommentSection = ({
  commentEntries,
}: {
  commentEntries: [string, ReviewAnswer][];
}) => {
  return (
    commentEntries.length > 0 && (
      <CommentGrid>
        {commentEntries.map(([key, value]) => (
          <StyledCommentContainer key={key}>
            <StyledCommentTitle>
              <DynamicIcon iconName={IconName.ChatBubble} />
              {value.label}
            </StyledCommentTitle>
            <StyledCommentText>
              <DynamicIcon iconName={IconName.FormatQuote} />
              {value.answer || 'N/A'}
            </StyledCommentText>
          </StyledCommentContainer>
        ))}
      </CommentGrid>
    )
  );
};

const BasicReviewReader = ({
  section,
  sectionTitle,
  scrollToTop,
}: {
  section?: ReviewSection | null;
  sectionTitle: string;
  scrollToTop: () => void;
}) => {
  const { isMobile } = useResponsive();
  const commentEntries = Object.entries(section ?? {}).filter(([key]) =>
    key.toLowerCase().includes('comment')
  ) as [string, ReviewAnswer][];

  const nonCommentEntries = Object.entries(section ?? {}).filter(
    ([key]) =>
      !key.toLowerCase().includes('comment') &&
      !key.toLowerCase().includes('list') &&
      key !== 'ratings'
  );

  const listEntries = Object.entries(section ?? {}).filter(
    ([key, value]) =>
      key.toLowerCase().includes('list') &&
      Array.isArray(value.answer) &&
      value.answer.length > 0
  ) as [string, ReviewAnswer][];

  if (!section) {
    return <ReviewNotFound />;
  }

  return (
    <ReviewContainer>
      <InfoContainer
        {...(!isMobile
          ? {
              initial: 'hidden',
              animate: 'visible',
              variants: fadeInVariants,
            }
          : {
              style: { opacity: 2, transform: 'translateY(0px)' },
            })}
      >
        <SectionHeaderReader
          headerSubtext={sectionTitle}
          scrollToTop={scrollToTop}
        />

        {listEntries.length > 0 && (
          <ListContainer>
            {listEntries.map(([key, value]) => (
              <ApproversListCard
                key={key}
                label={value.label}
                data={value.answer as string[]}
              />
            ))}
          </ListContainer>
        )}

        <BasicInfoWrapper
          display="grid"
          gridTemplateColumns={{ xs: '1fr 1fr', md: '1fr 1fr 1fr 1fr' }}
          gap={2}
        >
          {nonCommentEntries.map(([key, value]) => (
            <ReviewReaderBasicQuestion
              key={key}
              label={value.label}
              answer={value.answer as string}
              iconGroup={value.iconGroup}
            />
          ))}
        </BasicInfoWrapper>
        {commentEntries.length > 0 && (
          <CommentSection commentEntries={commentEntries} />
        )}

        {section.ratings && (
          <RatingsReader
            ratings={Array.isArray(section.ratings) ? section.ratings : []}
          />
        )}
      </InfoContainer>
    </ReviewContainer>
  );
};

export default BasicReviewReader;
