import { Button, Box } from '@mui/material';
import { useContext } from 'react';
import styled from 'styled-components';
import LoadingProgress from '../../../shared/components/LoadingProgress';
import { UserContext } from '../../../shared/contexts/UserContext';
import { StyledFormContainer } from '../../../shared/styles/FormStyles';
import AuthFormFields from '../../authentication/components/UserAuthFormFields';
import {
  AuthFormFieldsValues,
  FormFieldsValues,
} from '../../authentication/types/types.auth';
import { UPDATE_ACCOUNT_FORM_FIELDS } from '../types/types.account-settings';

type UpdateDetailsFormProps = {
  handleFormFieldChange: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  updateUser: (e: React.FormEvent<HTMLFormElement>) => void;
  industryValue?: string;
  formFields: AuthFormFieldsValues;
};

const StyledSubmitButton = styled.div`
  text-align: center;
  margin-top: var(--spacing-md);
`;

const UpdateDetailsForm = (props: UpdateDetailsFormProps) => {
  const { handleFormFieldChange, updateUser, industryValue } = props;

  const { user } = useContext(UserContext);

  if (!user) {
    return <LoadingProgress />;
  }

  return (
    <StyledFormContainer>
      <Box component="form" sx={{ mt: 3 }}>
        <AuthFormFields
          handleFormFieldChange={handleFormFieldChange}
          formFields={UPDATE_ACCOUNT_FORM_FIELDS}
          defaultValues={user as FormFieldsValues}
          isEdit={true}
          industryValue={industryValue}
        />

        <StyledSubmitButton>
          <Button
            type="submit"
            variant="contained"
            onClick={(e: any) => updateUser(e)}
          >
            Update Details
          </Button>
        </StyledSubmitButton>
      </Box>
    </StyledFormContainer>
  );
};
export default UpdateDetailsForm;
