import { AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type ReviewDialogHeaderProps = {
  companyName: string;
  handleClose: () => void;
  isDeepDive?: boolean;
};

const ReviewDialogHeader = (props: ReviewDialogHeaderProps) => {
  const { companyName, handleClose, isDeepDive } = props;

  return (
    <AppBar
      sx={{
        position: 'relative',
        backgroundColor: 'rgb(0, 158, 219)',
        color: 'white',
      }}
    >
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>

        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
          Leave a {isDeepDive ? <strong>Deep Dive</strong> : ''} review for{' '}
          {companyName}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default ReviewDialogHeader;
