export enum ReviewDepth {
  BASIC = 'BASIC',
  DEEP_DIVE = 'DEEP_DIVE',
}

export enum RatingAspect {
  CLARITY = 'clarity',
  RESPONSIVENESS = 'responsiveness',
  TRANSPARENCY = 'transparency',
  UNDERSTANDING = 'understanding',
}

export enum ReviewStep {
  BASIC_REVIEW,
  PURCHASE_DETAILS,
  EVALUATION,
  LEGAL,
  NEGOTIATION,
  RFP,
}

export type RatingSummary = {
  name?: string;
  rating: number;
  user: string;
  type: string;
};

export interface BaseReview {
  id?: number;
  ratings: Array<RatingSummary>;
  companyId?: number;
  userId?: number;
  companyName?: string;
  createdAt?: Date;
  reviewDepth?: ReviewDepth;
  reviewType?: string;
  viewed?: boolean;
}

export interface BasicReview extends BaseReview {
  dealSize: string;
  dealClose: string;
  dealLength: string;
  dealType: string;
  dealArea: string;
  dealRfp?: string;
  commentBuyerWell: string;
  commentBuyerBetter: string;
  commentWinLose: string;
  commentAdvice: string;
  dealDate?: string;
  viewed?: boolean;
}

export interface PurchaseDetailsReview extends BaseReview {
  championEffectiveness?: string;
  definedBudget?: string;
  salesCycleLength?: string;
  buyerFiscalYearEnd?: string;
  paymentCycle?: string;
  economicBuyerName?: string;
  championName?: string;
}

export interface EvaluationReview extends BaseReview {
  buyerReplacingVendor?: string;
  noDecisionMakers?: string;
  criteriaArticulatedByBuyer?: string;
  criteriaChangeComment?: string;
  securityRequirementsComment?: string;
  criteriaArticulatedByBuyerComment?: string;
  articulatingCriteriaComment?: string;
  requireTrial?: string;
  roiJustificationKPIsComment?: string;
  trialAccommodated?: string;
  platFormList?: string[];
  durationSecurityReview?: string;
  securityDifficulty?: string;
  submitRoi?: string;
  approversList?: string[];
  negotiateTrialComment?: string;
  trialLength?: string;
}

export interface LegalReview extends BaseReview {
  legalReviewLength?: string;
  legalReviewDifficulty?: string;
  marketingPermission?: string;
  autoRenewalClause?: string;
  paperworkOption?: string;
  customerAskLiability?: string;
  endAskLiability?: string;
  liabilityComment?: string;
  notableGenComment?: string;
  liabilityStrategiesComment?: string;
}

export interface NegotiationReview extends BaseReview {
  terminationConvenience?: string;
  forcedTerminationAcceptance?: string;
  forcedTerminationAcceptanceComment?: string;
  noticeHonored?: string;
  noticeSelection?: string;
  procurementThreshold?: string;
  procurementFairness?: string;
  procurementTactics?: string;
  competitiveBids?: string;
  discountGiven?: string;
  fundsReleased?: string;
  negotiationTipsComment?: string;
  fairnessComment?: string;
  procurementThresholdValue?: string;
}

export interface RfpReview extends BaseReview {
  rfpWin?: string;
  rfpInvolvement?: string;
  rfpInfluence?: string;
  rfpLength?: string;
  acceptanceFormatComment?: string;
  tipsComment?: string;
  influenceRfpProcessComment?: string;
}

export type CurrentReview =
  | BasicReview
  | PurchaseDetailsReview
  | EvaluationReview
  | LegalReview
  | NegotiationReview
  | RfpReview
  | null;
