import React, { useContext, useEffect, useRef, useState } from 'react';
import BasicReviewReader from './BasicReviewReader';
import axios from 'axios';
import { UserContext } from '../../shared/contexts/UserContext';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import CustomSnackbar, {
  SnackBarConfig,
} from '../../shared/components/Snackbar';
import Loader from '../../shared/components/Loader';
import ReviewBanner from './components/ReviewBanner';
import moment from 'moment';
import { motion } from 'framer-motion';
import { formatReviewData } from './helpers/FormatReadableReview';
import { CurrentReadableReview, ReviewSection } from './types/types';
import { useResponsive } from '../../shared/hooks/useResponsive';

const loadingMessages = [
  'Gathering absolute premium review data...',
  'Sifting through corporate nonsense...',
  'Translating legalese into something human-readable...',
  'Asking the database nicely to hurry up...',
  'Fetching insights… or at least trying to...',
  'Extracting wisdom from the void...',
  'Loading... because instant gratification is a myth.',
  'Prying open the vault of review secrets...',
  'Compiling expert opinions (or just opinions)...',
  'Waking up the servers, they were napping.',
  'Decrypting sales jargon for your convenience...',
  'Patching together words that sound smart...',
  'Negotiating with the API for faster responses...',
  'Fine-tuning insights with AI magic...',
  'Trying to make sense of procurement madness...',
  'Analyzing reviews with a dash of sarcasm...',
  'Extracting the good stuff, discarding the fluff...',
  'Rewiring the internet just for this review...',
  'Consulting the data oracles, please hold...',
  'Bribing the server hamsters for speed...',
];

const getRandomLoadingMessage = () =>
  loadingMessages[Math.floor(Math.random() * loadingMessages.length)];

const ReviewReader = () => {
  const { user } = useContext(UserContext);
  const { reviewId } = useParams();
  const queryParams = useParams();
  const navigate = useNavigate();

  const [currentReview, setCurrentReview] =
    useState<CurrentReadableReview | null>(null);
  const [snackbarConfig, setSnackbarConfig] = useState<SnackBarConfig | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);

  const handleSnackBarClose = () => {
    setSnackbarConfig((prev) => (prev ? { ...prev, open: false } : null));
  };

  const { isMobile } = useResponsive();

  const sectionRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const scrollToSection = (key?: string) => {
    if (!key) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }

    const section = sectionRefs.current[key];
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const getCurrentReview = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_API_BASE_URL}/review/${reviewId}/full`,
        {
          headers: { Authorization: user?.idToken },
        }
      );

      const formattedData: CurrentReadableReview = formatReviewData(
        response.data
      );
      setCurrentReview(formattedData);
    } catch (error) {
      const status = error.response?.status;

      if (status === 401) {
        navigate({
          pathname: '/sign-in',
          search: `?${createSearchParams({
            reviewId: String(reviewId),
            companyName: queryParams.name || '',
            reAuth: 'true',
          })}`,
        });
        return;
      }

      if (status === 402) {
        navigate({
          pathname: `/company-search/${queryParams.name}`,
          search: `?${createSearchParams({
            planMaxed: String(reviewId),
          })}`,
        });
        return;
      }

      setSnackbarConfig({
        type: 'error',
        message: 'An error has occurred, please try again later.',
        open: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!user?.idToken) return;
    getCurrentReview();
  }, [reviewId, user?.idToken]);
  if (loading) {
    return <Loader loaderText={getRandomLoadingMessage()} />;
  }
  if (!currentReview) {
    return null;
  }

  const sections = [
    {
      section: currentReview.reviewBasic,
      title: 'General Information',
      key: 'ReviewBasic',
    },
    currentReview.ReviewPurchaseDetail &&
    Object.keys(currentReview.ReviewPurchaseDetail).length > 0
      ? {
          section: currentReview.ReviewPurchaseDetail,
          title: 'Purchase Details',
          key: 'ReviewPurchaseDetail',
        }
      : null,
    currentReview.ReviewEvaluation &&
    Object.keys(currentReview.ReviewEvaluation).length > 0
      ? {
          section: currentReview.ReviewEvaluation,
          title: 'Evaluation',
          key: 'ReviewEvaluation',
        }
      : null,
    currentReview.ReviewNegotiation &&
    Object.keys(currentReview.ReviewNegotiation).length > 0
      ? {
          section: currentReview.ReviewNegotiation,
          title: 'Negotiation',
          key: 'ReviewNegotiation',
        }
      : null,
    currentReview.ReviewLegal &&
    Object.keys(currentReview.ReviewLegal).length > 0
      ? {
          section: currentReview.ReviewLegal,
          title: 'Legal',
          key: 'ReviewLegal',
        }
      : null,
    currentReview.ReviewRfp && Object.keys(currentReview.ReviewRfp).length > 0
      ? {
          section: currentReview.ReviewRfp,
          title: 'RFP Details',
          key: 'ReviewRfp',
        }
      : null,
  ].filter(
    (item): item is { section: ReviewSection; title: string; key: string } =>
      item !== null
  );

  return (
    <>
      <ReviewBanner
        companyName={currentReview.companyName || ''}
        reviewDate={moment(currentReview?.createdAt).format('ll')}
        onScrollToSection={scrollToSection}
        sections={sections}
      />

      {sections.map(({ key, title, section }) =>
        section ? (
          isMobile ? (
            <div
              key={key}
              ref={(el) => {
                if (el) sectionRefs.current[key] = el;
              }}
              id={key}
            >
              <BasicReviewReader
                section={section}
                sectionTitle={title}
                scrollToTop={scrollToSection}
              />
            </div>
          ) : (
            <motion.div
              key={key}
              ref={(el) => {
                if (el) sectionRefs.current[key] = el;
              }}
              id={key}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: false, amount: 0.3 }}
              transition={{ duration: 0.6, ease: 'easeOut' }}
            >
              <BasicReviewReader
                section={section}
                sectionTitle={title}
                scrollToTop={scrollToSection}
              />
            </motion.div>
          )
        ) : null
      )}

      {snackbarConfig && (
        <CustomSnackbar config={snackbarConfig} setOpen={handleSnackBarClose} />
      )}
    </>
  );
};

export default ReviewReader;
